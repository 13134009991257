import { Injectable, ViewEncapsulation, NgZone } from '@angular/core';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  encapsulation: ViewEncapsulation.None;
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  actionButtonLabel = 'Retry';
  action = true;
  constructor(
  public snackBar: MatSnackBar,
  private zone: NgZone
    ) { }

  openSnackBar(message, snackClass, setAutoHide?) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = [snackClass];
    this.zone.run(() => {
        this.snackBar.open(message, null,  config);
    });
}
}
