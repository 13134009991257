import {
  Injectable
} from '@angular/core';
import {
  AngularFirestore
} from '@angular/fire/firestore';
import { timeStamp } from 'console';
import firebase from 'firebase/app';
import {
  first,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  DateAndTimeService
} from './date-and-time.service';
import {
  FileUploadService
} from './file-upload.service';
import { UnitedStatesListService } from './united-states-list.service';

@Injectable({
  providedIn: 'root'
})
export class ReadService {

  branchList: any;
  d: any;
  constructor(
    private afs: AngularFirestore,
    private dateTime: DateAndTimeService,
    private fileUploadService: FileUploadService,
    private dateTimeService: DateAndTimeService,
    private usStates: UnitedStatesListService
  ) {

    this.branchList = this.afs.collection('cscBranchDirectory',
        ref => ref.orderBy('branchName', 'asc'))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
        //    console.log(data);

            return data;
          });
        })
      );

  }
  getBranches() {
    return this.branchList;
  }

  async returnEmployeesPromiseOrderBy(
    collection,
    field,
    direction
  ) {
    const d = await firebase.firestore().collection(collection)
      .orderBy(field, direction)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(x => {
      if (x) {
         const dataList = x.data();

         const obj = {
           optionName: ` ${x.data().personLegalNameFirst} ${x.data().personLegalNameLast} (${x.data().personEmail})`,
           value: `${x.data().personEmail}`,
           img: `${x.data().personImageUrl}`,
           personEmail: `${x.data().personEmail}`
         }
        // const obj = this.dateTime.convertTimestampFn(dataList);
        // console.log(dataList);
        data.push(obj);
      }
    });
    return data;
  }
  async returnPromiseOrderBy(
    collection: string,
    field: string,
    direction: any
  ) {
    const d = await firebase.firestore().collection(collection)
      .orderBy(field, direction)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(x => {
      if (x) {
        const dataList = x.data();
        const obj = this.dateTime.convertDate(dataList);
        if (obj.storagePath) {
          const getImg =  this.fileUploadService.getPublicUrl(obj.storagePath);
          getImg.subscribe(x => {
            obj.imageUrl = x;
          });
        }
        data.push(obj);
      }
    });
   // console.log('V DATA', data);
    return data;
  }
async returnUSStaestPromiseFn(){
   //  console.log(this.usStates.usStatesListFn());
  return this.usStates.usStatesListFn();
}
  async returnSelectOptionsPromiseOrderBy(
    collection,
    fieldName,
    fieldValue,
    direction
  ) {
    // console.log(collection);
    // console.log(fieldName);
    // console.log(fieldValue);
    // console.log(direction);
    const d = await firebase.firestore().collection(collection)
      .orderBy(fieldName, direction)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(x => {
      if (x) {
         const dataList = x.data();
         const obj = {
           optionName: ` ${x.data()[fieldName]}`,
           value: `${x.data()[fieldValue]}`,
         }
        // const obj = this.dateTime.convertTimestampFn(dataList);
      //   console.log(dataList);
        data.push(obj);
      }
    });
    return data;
  }

  async returnPromiseWhereAndFilterSingleFieldFn(
    collection,
    property,
    value,
    filteredProperty
  ) {
    const d = await firebase.firestore().collection(collection)
      .where(property, '==', value)
      .get();
    const data = [];
    d.forEach(x => {
      if (x) {
         const obj = x.data()[filteredProperty];
      //   console.log(obj);
        data.push(obj);
      }
    });
    return data;
  }
  async returnPromiseWhereAndWhereFn(
    collection,
    wherePropertyOne,
    whereValueOne,
    wherePropertyTwo,
    whereValueTwo
  ) {
    const d = await firebase.firestore().collection(collection)
      .where(wherePropertyOne, '==', whereValueOne)
      .where(wherePropertyTwo, '==', whereValueTwo)
      .get();
    const data = [];
    d.forEach(x => {
      if (x) {
         const obj = x.data();
      //   console.log(obj);
        data.push(obj);
      }
    });
    return data;
  }
  async returnPromiseOrderByAndFilterSingleFieldFn(
    collection,
    property,
    value,
    filteredProperty
  ) {
    const d = await firebase.firestore().collection(collection)
      .orderBy(property, value)
      .get();
    const data = [];
    d.forEach(x => {
      if (x) {
         const obj = x.data()[filteredProperty];
      //   console.log(obj);
        data.push(obj);
      }
    });
    return data;
  }

  async fetchFirestorePromiseFilterPropertyOrderBy(collection, field, direction) {

    const d = await firebase.firestore().collection(collection)
      .orderBy(field, direction)
      .get();
    // const res = from(d);
    const list = [];
    d.forEach(x => {
      if (x) {
        const dataList = x.data();
      //  console.log(dataList);
        list.push(dataList);
      }
    });
    return list;
  }

  async returnPromiseWhere(
    collection: string,
    property: string,
    value: any
  ) {
    const d = await firebase.firestore().collection(collection)
      .where(property, '==', value)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(async x => {
      if (x) {
        const dataList = x.data();
        const obj = await this.dateTime.convertDate(dataList);

        if (obj.storagePath) {
          const getImg =  this.fileUploadService.getPublicUrl(obj.storagePath);
          getImg.subscribe(x => {
            obj.imageUrl = x;
          });
        }
      //  console.log('DATALIST', obj);
        data.push(obj);
        // const o = {
        //   ...obj
        // };
       // console.log('O', o);
      }
    });
  //  console.log('DATA', data);

    return data;
  }
  async returnPromiseWhereFn(
    collection: string,
    property: string,
    value: any
  ) {
    // console.log('collection', collection);
    // console.log('property', property);
    // console.log('value', value);
    const d = await firebase.firestore().collection(collection)
      .where(property, '==', value)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(async x => {
      if (x) {
        const dataList = x.data();
        const obj = await this.dateTime.convertDate(dataList);
        data.push(obj);
       }
    });
  //  console.log('DATA', data);
    return data;
  }

  async returnPromiseOrderByAndWhereFilterFn(collectionPath: any, whereProperty: string, whereValue: any, orderProperty: string, orderValue: any,  ) {
    const d = await firebase.firestore().collection(collectionPath)
    .where(whereProperty, '==', whereValue)
    .orderBy(orderProperty, orderValue)
    .get();
    const data = [];
    d.forEach(x => {
      if (x) {
        const dataList = x.data();
        const obj = this.dateTime.convertDate(dataList);
      //  console.log('OBJ', obj);
        data.push(obj);
      }
    });
  //  console.log('DATA', data);

    return data;
  }

  async returnPromiseOrderByAndWhereFilterPastDateFn(collectionPath: any, whereProperty: string, whereValue: any, orderProperty: string, orderValue: any,  ) {
    const d = await firebase.firestore().collection(collectionPath)
    .where(whereProperty, '>=', whereValue)
    .orderBy(orderProperty, orderValue)
    .get();
    const data = [];
    d.forEach(x => {
      if (x) {
        const dataList = x.data();
        const obj = this.dateTime.convertDate(dataList);
     //  console.log('OBJ', obj);
        data.push(obj);
      }
    });
  //  console.log('DATA', data);

    return data;
  }

  async returnPromiseFieldOrderBy(
    collection,
    field,
    direction
  ) {
    // console.log('collection', collection);
    // console.log('field', field);
    // console.log('direction', direction);
    const d = await firebase.firestore().collection(collection)
      .orderBy(field, direction)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(x => {
      if (x) {
        const dataList = x.data().personEmail;
        const obj = this.dateTime.convertDate(dataList);
      //  console.log('OBJ', obj);
        data.push(obj);
      }
    });
  //  console.log('DATA', data);

    return data;
  }

  async returnPromiseWhereSingleProperty(
    collection,
    field,
    val,
    property
  ) {
    const d = await firebase.firestore().collection(collection)
      .where(field, '==', val)
      .get();
    // const res = from(d);
    const data = [];
    d.forEach(async x => {
      if (x) {
        const dataList = `${x.data()[property]}`;
        data.push(dataList);
      }
    });
  //  console.log('DATA', data);
    return data;
  }
  async returnCollectionGroupPromiseWhereFn(collection: any, field: any, value: any) {
   //  console.log(collection, field, value);
    const db =  firebase.firestore();
    const ref = await db.collectionGroup(collection).where(field, '==', value).get();
   // console.log('CG REF', ref);
    const data = [];
    ref.forEach(x => {
      if (x) {
        const dataList = x.data();
        const obj = this.dateTime.convertDate(dataList);
        obj.path = x.ref.path;
      // console.log('OBJ', obj);
        data.push(obj);
      }
    });
    // console.log('DATA', data);
    return data;
   }

  returnAllRecordsFn(collection: string, field: string, direction: any) {
   // console.log();
    return this.afs.collection(collection, ref => ref.orderBy(field, direction))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
            if (data.storagePath) {
              const getImg = this.fileUploadService.getPublicUrl(data.storagePath);
              getImg.subscribe(x => {
                data.imageUrl = x;
              });
            }
        //  console.log('DT', data);
          const obj = this.dateTime.convertDate(data);
          return obj;
          });
        })
      );
  }
  // returnAllRecordsFn(collection: string, field: string, direction: any) {
  //   console.log();
  //   return this.afs.collection(collection, ref => ref.orderBy(field, direction))
  //     .snapshotChanges()
  //     .pipe(
  //       map((docArray: any) => {
  //         return docArray.map((doc: any) => {
  //           const data = doc.payload.doc.data();
  //           if (data.storagePath){
  //            data.imageUrl =  this.fileUploadService.getPublicUrl(data.storagePath);
  //           }
  //           const obj = this.dateTime.convertTimestampFn(data);
  //           return data;
  //         });
  //       })
  //     );
  // }

  returnOrderByFilterFn(collection: string, field: string, direction: any, filterProperty: string, filterValue: string) {
     console.log(collection);
     console.log(field);
     console.log(direction);
     console.log(filterProperty);
     console.log(filterValue);
    return this.afs.collection(collection, ref => ref.orderBy(field, direction))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
            if (data.storagePath) {
              const getImg = this.fileUploadService.getPublicUrl(data.storagePath);
              getImg.subscribe(x => {
                data.imageUrl = x;
              });
            }
            const obj = this.dateTime.convertDate(data);
     //   console.log('FILTER OBJ', obj);
            return obj;
          }).filter((data: object) => data[filterProperty] === filterValue);
        })
      );
  }
  returnObservableWhereFilterFn(
    collection,
    field,
    val,
    filterProperty,
    filterValue
    ) {
    // console.log();
    return this.afs.collection(collection, ref => ref.where(field, '==', val))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
            const obj = data;
            // const obj = this.dateTime.convertTimestampFn(data);
            return obj;
          }).filter((data: object) => data[filterProperty] === filterValue);
        })
      );
  }

 returnRecordsWhereFn(collection: string, property: string, value: any) {
    // console.log(collection);
    // console.log(field);
    // console.log(id);
    return this.afs.collection(collection, ref => ref.where(property, '==', value))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
            if(!data.imageUrl){
                if (data.storagePath) {
              const getImg =  this.fileUploadService.getPublicUrl(data.storagePath);
              getImg.subscribe(x => {
                data.imageUrl = x;
              });
            }
            }

        //   console.log('RECORDS WHERE', data);
           const obj = this.dateTime.convertDate(data);
            return obj;
          });
        })
      );
  }
 returnObservableWhereFn(collection: string, property: string, value: any) {
    // console.log(collection);
    // console.log(field);
    // console.log(id);
    return this.afs.collection(collection, ref => ref.where(property, '==', value))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
         //   console.log('RECORDS WHERE', data);
           const obj = this.dateTime.convertDate(data);
            return obj;
          });
        })
      );
  }

  returnCollectionGroupExpiryObservableOrderByFn(collection, whereProperty, whereValue, orderProperty, orderValue) {
    // console.log();
    return this.afs.collectionGroup(collection, ref => ref.where(whereProperty, '==', whereValue)
    .orderBy(orderProperty, orderValue))
      .snapshotChanges()
      .pipe(
        map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
            //   console.log(data);
            if (data.personGuardCardExpirationDate) {
              console.log('Guard Card Expiration Date', data.personGuardCardExpirationDate);
              //   let contractEndDate = data.personContractEndDate;
              //   contractEndDate = contractEndDate.valueOf();
              //   contractEndDate = Math.floor((contractEndDate.seconds) * 1000);
              //  console.log(data.contractEndDateTimestamp);
              const _MS_PER_DAY = 1000 * 60 * 60 * 24;
              //  const _MS_PER_DAY = 86400 * 1000;
              // data.expiryTimestamp = Math.ceil((data.expiryTimestamp) / _MS_PER_DAY);
              const today = new Date();
              // const timestamp = today.valueOf();
              const timestamp = Math.floor(today.getTime()/1000);

             
               console.log('TODAY', timestamp);
              const testtime = (data.personGuardCardExpirationDate - timestamp) / _MS_PER_DAY;
              //   console.log('TEST TIMESTAMP', testtime);
              const firebaseSeconds = data.personGuardCardExpirationDate.seconds;
              const secondsPerDay = 86400;
              console.log('Firebase Seconds', firebaseSeconds);
              data.expiryDays = Math.ceil((firebaseSeconds - timestamp) / secondsPerDay);
                console.log('Days to Exp', data.expiryDays);
            }
            this.dateTime.convertDate(data);
            return data;
          });
        })
      );
  }

  returnCollectionGroupRecordsWhereFn(collection: any, field: any, value: any) {
  // console.log(collection, field, value);
   return this.afs.collectionGroup(collection, ref => ref.where(field, '==', value))
     .snapshotChanges()
     .pipe(
         map((docArray: any) => {
           return docArray.map(doc => {
            const data = doc.payload.doc.data();
           const obj = this.dateTime.convertDate(data);
         // console.log('Collection GRP', obj);
           return obj;
           });
         })
     );
 }
 returnCollectionGroupRecordsWhereAndWhereFilterFn(collection: any, whereField: any, whereValue: any, whereFilter: any, whereFilterVal) {
  // console.log(collection, field, value);
   return this.afs.collectionGroup(collection, ref => ref.where(whereField, '==', whereValue)
   .where(whereFilter, '==', whereFilterVal))
     .snapshotChanges()
     .pipe(
         map((docArray: any) => {
           return docArray.map(doc => {
            const data = doc.payload.doc.data();
           const obj = this.dateTime.convertDate(data);
         // console.log('Collection GRP', obj);
           return obj;
           });
         })
     );
 }
  returnCollectionGroupRecordsWhereAndOrderByFn(collection: any, whereField: any, whereValue: any, orderProperty: any, orderValue: any) {
   console.log('collection', collection);
    console.log('whereField', whereField);
    console.log('whereValue', whereValue);
    console.log('orderProperty', orderProperty);
    console.log('orderValue', orderValue);
   return this.afs.collectionGroup(collection, ref => ref.where(whereField, '==', whereValue)
   .orderBy(orderProperty, orderValue))
     .snapshotChanges()
     .pipe(
         map((docArray: any) => {
           return docArray.map(doc => {
            const data = doc.payload.doc.data();

           const obj = this.dateTime.convertDate(data);
         // console.log('Collection GRP', obj);
           return obj;
           });
         })
     );
 }
  returnCollectionGroupRecordsWhereAndOrderByAndFilterFn(collection: any, whereField: any, whereValue: any, orderProperty: any, orderValue: any, filterProperty: any, filterValue: any, ) {
    console.log('collection', collection);
    console.log('whereField', whereField);
    console.log('whereValue', whereValue);
    console.log('orderProperty', orderProperty);
    console.log('orderValue', orderValue);
    console.log('filterProperty', filterProperty);
    console.log('filterValue', filterValue);
   return this.afs.collectionGroup(collection, ref => ref.where(whereField, '==', whereValue)
   .orderBy(orderProperty, orderValue))
     .snapshotChanges()
     .pipe(
         map((docArray: any) => {
           return docArray.map(doc => {
            const data = doc.payload.doc.data();

           const obj = this.dateTime.convertDate(data);
         // console.log('Collection GRP', obj);
           return obj;
          }).filter((data: object) => data[filterProperty] === filterValue);

         })
     );
 }
  returnCollectionGroupRecordsWhereAndOrderByFilterPastDateFn(collection: any, whereField: any, whereValue: any, orderProperty: any, orderValue: any, filterProperty: any, filterValue: any) {
  // console.log(collection, whereField, whereValue);
   return this.afs.collectionGroup(collection, ref => ref.where(whereField, '==', whereValue)
   .orderBy(orderProperty, orderValue))
     .snapshotChanges()
     .pipe(
         map((docArray: any) => {
           return docArray.map(doc => {
            const data = doc.payload.doc.data();

           const obj = this.dateTime.convertDate(data);
         // console.log('Collection GRP', obj);
           return obj;
           }).filter((data: object) => data[filterProperty] >= filterValue);;
         })
     );
 }


  returnRecordsOrderByFn(collection: any, field: any, direction: any) {
  //  console.log(collection, field, direction);
   return this.afs.collection(collection, ref => ref.orderBy(field, direction))
     .valueChanges()
     .pipe(
         map((data: any) => {
         // ('EVENT DATA', data);
          const obj = this.dateTime.convertDate(data);
          return obj;
         })
     );
 }

 returnObservableOrderByFn(collection: any, field: any, direction: any) {
  //  console.log(collection, field, direction);
   return this.afs.collection(collection, ref => ref.orderBy(field, direction))
     .valueChanges()
     .pipe(
         map((data: any) => {
         // ('EVENT DATA', data);
          const obj = this.dateTime.convertDate(data);
          return obj;
         })
     );
 }

 returnSwitchMapRecordsOrderByFn(collection: any, field: any, direction: any) {
  //  console.log(collection, field, direction);
   return this.afs.collection(collection, ref => ref.orderBy(field, direction))
     .valueChanges()
     .pipe(
         switchMap((data: any) => {
           // const data = doc.payload.doc.data();
         //  console.log('RECS', data);
           return data;
         })
     );
 }

 returnRecordsTakeOneOrderByFn(collection: any, field: any, direction: any) {
  // console.log(collection, field, direction);
  return this.afs.collection(collection, ref => ref.orderBy(field, direction))
    .snapshotChanges()
    .pipe(
      take(1),
      map((docArray: any) => {
        return docArray.map((doc: any) => {
          const data = doc.payload.doc.data();

        //  console.log('DT', data.docId);
          // const obj = this.dateTime.convertTimestampFn(data);
          return data.docId;
        });
      })
    );
}

  returnFilteredDateRecordsOrderByFn(collection: any, property: any, value: any) {
  //  console.log(collection, property, direction);
   const outer = this.afs.collection(collection, ref => ref.orderBy(property, value))
     .snapshotChanges();

   return outer.pipe(
         map((docArray: any) => {
          return docArray.map((doc: any) => {
            const data = doc.payload.doc.data();
   //   console.log('RECS', data);
      const today = new Date();
      const todayTimestamp = today.valueOf();
    //  console.log('today', today);
    //  console.log('todayTimestamp', todayTimestamp);
    //  console.log('eventTimestamp', data.eventTimestamp);


      if(todayTimestamp < data.eventTimestamp){
     // console.log('FILTERED DATE', data);
        return data;
      }
     // return data;
     });
   }));
 }

}
