<div id="" class="pageWrapper">
  <div class="pageTitle">
    <h1><span class="cscMidnightBlueText">Clients</span> <span class="cscGoldText"></span></h1>
  </div>

  <div class="sectionWrapper">
    <h2>Special Events</h2>
<div class="pillWrapper" >
  <div class="events" *ngFor="let x of specialEvents | async">
   <p>{{x.clientTitle}}</p>
  </div>
</div>
  </div>
  <div class="sectionWrapper">
    <h2>Sports</h2>
<div class="pillWrapper" >
  <div class="sports" *ngFor="let x of sports | async">
 <p class="flex">
   {{x.clientTitle}}
 </p>
  </div>
</div>
  </div>
  <div class="sectionWrapper">
    <h2>Venues</h2>
<div   class="pillWrapper">
 <div class="venues" *ngFor="let x of venues | async">
<p>{{x.clientTitle}}</p>
 </div>
</div>
  </div>


  </div>
