import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';

import Quill from 'quill'
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { pluck } from 'rxjs/operators';
import { SEOService } from 'src/app/services/seo.service';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  readonly articles$ = this.route.data.pipe(pluck('homePage'));

  blured = false
  focused = false
  modules = {}
  constructor(private seoService: SEOService,
    private route: ActivatedRoute) {
    // this.modules = {
    //   'emoji-shortname': true,
    //   'emoji-textarea': false,
    //   'emoji-toolbar': true,
    //   blotFormatter: {

    //   },
    //   'toolbar': {
    //     container: [
    //       ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    //       ['blockquote', 'code-block'],

    //       [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    //       [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //       [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    //       [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    //       [{ 'direction': 'rtl' }],                         // text direction

    //       [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    //       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    //       [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //       [{ 'font': [] }],
    //       [{ 'align': [] }],

    //       ['clean'],                                         // remove formatting button

    //       ['link', 'image', 'video'],                         // link and image, video
    //       ['emoji'],
    //     ],
    //     handlers: { 'emoji': function () { } },

    //   }
    // }
   }

  ngOnInit(): void {
    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }

}
