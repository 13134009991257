<div class="pageWrapper">
  <ng-container *ngIf="formConfigUser">
    <app-dynamic-form
      (submittedFormData)="receivedDynamicDataFn($event)"
      [f]="formConfigUser"
    >
      <span class="btnLabel">Submit</span>
    </app-dynamic-form>
  </ng-container>
</div>
<div class="signUpList">
  <div *ngFor="let x of list | async">
    <div class="jobApplicant">
      <div>
        {{ x.company | uppercase }}
      </div>
      <div>{{ x.personLegalNameFirst }} {{ x.personLegalNameLast }}</div>
      <div>
        {{ x.personEmail }}
      </div>
      <!-- <ng-container *ngIf="x.personCellPhone">

    </ng-container> -->
      <div>
        <span *ngIf="x.personCellPhone"> {{ x.personCellPhone }}</span>
      </div>
      <div>
        {{ x.selectedJob }}
      </div>
      <div>
        {{ x.timeSlot }}
      </div>
    </div>
  </div>
</div>
