import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  constructor(private afs: AngularFirestore) { }

  createItRecordFn(collection: string, docId: string, formData: object){
  //  console.log(collection);
  //  console.log(docId);
  //  console.log(formData);

    return this.afs.collection(collection).doc(docId)
    .set(formData, {
      merge: true
    });
  }
  createRecordFn(collection: string, docId: string, formData: object){
  //  console.log(collection);
  //  console.log(docId);
  //  console.log(formData);
    return this.afs.collection(collection).doc(docId)
    .set(formData, {
      merge: true
    });
  }
}
