import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavRoutingModule } from './nav-routing.module';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [NavRoutingModule.components],
  imports: [
    CommonModule,
    NavRoutingModule,
    SharedModule
  ],
  exports: [NavRoutingModule.components]
})
export class NavModule { }
