import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button-with-state',
  templateUrl: './back-button-with-state.component.html',
  styleUrls: ['./back-button-with-state.component.css']
})
export class BackButtonWithStateComponent implements OnInit {

  constructor(  public location: Location) { }

  ngOnInit(): void {

  }

  backButtonWithState() {
    return this.location.back();
  }
}
