<div [class]="tableClass">

  <ng-container>
    <div class="datatableWrapper">
      <!-- <ng-container *ngIf="showSpinner">
        <mat-spinner diameter="20">Loading</mat-spinner>
      </ng-container> -->


      <ng-container *ngIf="tableDataSrc.data.length > 0">
        <h3>{{ tableHeading }}: {{ tableDataSrc.data.length }}</h3>
        <mat-form-field>
          <input
            matInput
            (keyup)="dataFilter($event.target.value)"
            placeholder="Search"
          />
        </mat-form-field>
      </ng-container>
      <table
        mat-table
        [dataSource]="tableDataSrc"
        matSort
      >
        <ng-container
          *ngFor="let col of tableCols"

          matColumnDef="{{ col.columnDef }}"
        >

          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ col.header | uppercase }}
          </th>



          <td class="grid view" mat-cell *matCellDef="let e">
            <div ngSwitch="{{ col.type }}">
              <!-- <p>Type: {{col.type}}</p> -->

              <div *ngSwitchCase="'recordDetail'">
                <a
                  (click)="
                    showDetailFn(e[col.columnDef], e[col.docId], col.urlPath)
                  "
                >
                  <button type="button" mat-raised-button class="cscButtons">
                    Detail
                  </button>
                </a>
              </div>

              <div *ngSwitchCase="'signedUrl'">
                <a
                  (click)="viewCloudFileFn(e[col.columnDef], e.itemDepartment)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    appShowSpinner="isSaving"
                    mat-raised-button
                    class="cscBtn"
                  >
                    SIGNED URL
                  </button>
                </a>
              </div>
              <div *ngSwitchCase="'publicUrl'">
                <a
                  (click)="viewPublicFileFn(e[col.columnDef], e.itemDepartment)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    appShowSpinner="isSaving"
                    mat-raised-button
                    class="cscBtn"
                  >
                    View
                  </button>
                </a>
              </div>

              <div *ngSwitchCase="'emitData'">
                <a (click)="addRecordFn(e[col.columnDef])">
                  <button
                    appShowSpinner="isSaving"
                    mat-raised-button
                    class="greenBtn"
                  >
                    {{ col.btnName }}
                  </button>
                </a>
              </div>
              <div *ngSwitchCase="'editRecord'">
                <a (click)="editRecordFn(e[col.columnDef])">
                  <mat-icon>edit</mat-icon>
                </a>
              </div>
              <div *ngSwitchCase="'deleteRecord'">
                <a (click)="deleteRecordFn(e[col.columnDef])">
                  <mat-icon>delete</mat-icon>
                </a>
              </div>
              <div *ngSwitchCase="'workEventRequest'">
                <a
                  (click)="
                    workEventRequestFn(e[col.columnDef], e[col.venueDocId])
                  "
                >
                  <mat-icon>pending_actions</mat-icon> Apply
                </a>
              </div>
              <div *ngSwitchCase="'confirmWorkEvent'">
                <a
                  (click)="
                    confirmEmployeeEventApplicationFn(
                      e[col.columnDef],
                      e[col.venueDocId],
                      e[col.email],
                      e[col.eventTitle],
                      e[col.eventVenue],
                      e[col.startDate],
                      e[col.branchName]
                    )
                  "
                >
                  <button type="button" mat-raised-button class="cscButtons">
                    Confirm
                  </button>
                </a>
              </div>
              <div *ngSwitchCase="'removeWorkEvent'">
                <a
                  (click)="
                    removeEmployeeEventApplicationFn(
                      e[col.columnDef],
                      e[col.venueDocId],
                      e[col.email]
                    )
                  "
                >
                  <button type="button" mat-raised-button class="redBtn">
                    Remove
                  </button>
                </a>
              </div>

              <div *ngSwitchCase="'name'">
                <span [class]="col.class">{{ e[col.columnDef] }}</span>
              </div>
              <div *ngSwitchCase="'fullName'">
                <ng-container *ngIf="e[col.columnDef]; else noFullName">
                  <div class="">
                    <span [class]="col.class"
                      >{{ e[col.columnDef] | titlecase }}
                      {{ e[col.additionalNameField] | titlecase }}</span
                    >
                  </div>
                </ng-container>
                <ng-template #noFullName>
                  <p>No Data</p>
                </ng-template>
              </div>
              <div *ngSwitchCase="'boolean'">

             <!-- HAS   {{e.hasOwnProperty(col.property1)}}
             AND   {{e.hasOwnProperty(col.propertyName)}} -->
                <!-- {{e[col.bioApprovalStatusUpdatedBy]}} -->
                <ng-container *ngIf="!e.hasOwnProperty(col.propertyName) && !e.hasOwnProperty(col.property1) ">

                  <ng-container>
                  <div class="">
                    <span [class]="col.class">{{
                      col.booleanNotFound | uppercase
                    }} </span>
                  </div>
                  </ng-container>
                </ng-container>


                <ng-container >
                  <ng-container *ngIf="!e.hasOwnProperty(col.propertyName) && e.hasOwnProperty(col.property1)">
              <div class="">
                    <span [class]="col.class">{{
                      col.booleanMissing | uppercase
                    }}</span>

                  </div>

                  </ng-container>

                </ng-container>
                <ng-container *ngIf="(col.property1) && e[col.columnDef] === true">
                  <div class="">

                    <span [class]="col.class">{{
                      col.booleanTrue | uppercase
                    }}</span>

                  </div>
                </ng-container>
                <ng-container *ngIf="col.property1 && e[col.columnDef] === false">

                  <div class="">
                    <span [class]="col.class">{{
                      col.booleanFalse | uppercase
                    }}</span>

                  </div>
                </ng-container>
              </div>

              <div *ngSwitchCase="'titleCaseText'">
                <ng-container *ngIf="e[col.columnDef]">
                  <div class="">
                    <span [class]="col.class">{{
                      e[col.columnDef] | titlecase
                    }}</span>
                  </div>
                </ng-container>
              </div>
              <div *ngSwitchCase="'img'">
                <div [class]="col.class">
                  <ng-container *ngIf="e[col.columnDef]; else noImg">
                    <img loading="lazy" [src]="e[col.columnDef]" alt="" />
                  </ng-container>
                  <ng-template #noImg>
                    <ng-container *ngFor="let x of miscImages | async">
                      <img loading="lazy" [src]="x.imgUrl" alt="No Image Available" />
                    </ng-container>
                  </ng-template>
                </div>
              </div>

              <div *ngSwitchCase="'date'">
                <span [class]="col.class">
                  <span class="">
                    {{ e[col.columnDef] | date: "MM/dd/yyyy" }}
                  </span>
                  <!-- <span class="mobile">
                  {{e[col.columnDef] | date: 'MM/dd/yyyy'}}
                </span>
                <span class="tablet">
                  {{e[col.columnDef] | date: 'MMM dd, yyyy'}}
                </span>
                <span class="desktop">
                  {{e[col.columnDef] | date: 'MMMM dd, yyyy'}}
                </span> -->
                </span>
              </div>
              <div *ngSwitchCase="'expiryDays'">
                <div [ngClass]="{
                  'isLessThan0':   e[col.columnDef] <= 0,
                  'isLessThan30':   e[col.columnDef] >= 0  &&    e[col.columnDef] <=  30,
                  'isLessThan60':   e[col.columnDef] >= 31  &&    e[col.columnDef] <= 60,
                  'isLessThan90':   e[col.columnDef] >= 61  &&   e[col.columnDef] < 90,
                  'isMoreThan90':   e[col.columnDef] >= 91
               }">
          <ng-container *ngIf="  e[col.columnDef] == 0  ||   e[col.columnDef] <= 0; else days">
            <span>EXPIRED</span>

          </ng-container>
          <ng-template #days>
            <ng-container *ngIf="  e[col.columnDef] == 1  ||   e[col.columnDef] == -1">
              {{  e[col.columnDef]}} day
            </ng-container>
            <ng-container *ngIf="  e[col.columnDef] > 1 ||   e[col.columnDef] == 0 ||   e[col.columnDef] <= -2">
              {{  e[col.columnDef]}} days
            </ng-container>
          </ng-template>
        </div>
              </div>
              <div *ngSwitchCase="'employeeId'">
                {{ e[col.columnDef] }}
              </div>
              <div *ngSwitchCase="'ssn'">
                {{ e[col.columnDef] }}
              </div>

              <div *ngSwitchCase="'email'">
                <span class="email">
                  <a href="mailTo:{{ e[col.columnDef] }}">
                    {{ e[col.columnDef] }}
                  </a>
                </span>
              </div>
              <div *ngSwitchCase="'phone'">
                <ng-container *ngIf="e[col.columnDef]; else noPhone">
                  <span class="phone">
                    <a href="tel:{{ e[col.columnDef] | phoneFormat: 'US' }}">
                      {{ e[col.columnDef] | phoneFormat: "US" }}
                    </a>
                  </span>
                </ng-container>

                <!-- <ng-container *ngIf="e[col.columnDef] === null || !e[col.columnDef] || e[col.columnDef] === '' || e[col.columnDef] === 'null'">
                  No Number Listed
                </ng-container> -->

                <ng-template #noPhone> Not Listed </ng-template>
              </div>
              <!-- <div *ngSwitchCase="'phone'">
              <ng-container *ngIf="e[col.columnDef] || e[col.columnDef] === null; else noPhone">
                <span class="phone">
                  <a href="tel:{{e[col.columnDef] | phoneFormat: 'US'}}">
                    {{e[col.columnDef] | phoneFormat: 'US'}}
                  </a>
             </span>
              </ng-container>
             <ng-template #noPhone>
               Not Listed
             </ng-template>
              </div> -->
              <div *ngSwitchCase="'empStatus'">
                <span
                  [ngClass]="
                    e[col.columnDef] === 'active' ? 'greenBtn' : 'redBtn'
                  "
                >
                  {{ e[col.columnDef] | titlecase }}
                </span>
              </div>
              <div *ngSwitchDefault>
                {{ e[col.columnDef] | titlecase }}
              </div>
              <div *ngSwitchCase="'delete'">
                <!-- <mat-icon class="delIcon"
                (click)="openDeleteDialogFn(col.collection, e[col.columnDef])"align="end" color="warn" inline=true>
                  delete_forever</mat-icon> -->
              </div>
            </div>

            <!-- <pre>
              <p>E</p>
              {{e | json}}
            </pre> -->
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="formattedCols"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: formattedCols; let i = index"
          class="customRowStyle"
          [class.customRowStyleAlternate]="i % 2"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No Records Found</td>
        </tr>
      </table>
      <mat-paginator
        [length]="dataTableLength"
        [pageSize]="dataTablePageSize"
        [pageSizeOptions]="dataTablePageSizeOptions"
      >
      </mat-paginator>
    </div>
  </ng-container>
  <ng-template #noData>There are no records.</ng-template>
</div>
