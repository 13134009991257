import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { CreateService } from 'src/app/services/create.service';
import { GlobalConstants } from 'src/app/global-variables';
import { AngularFirestore } from '@angular/fire/firestore';

Quill.register('modules/blotFormatter', BlotFormatter);
// import 'quill-emoji/dist/quill-emoji.js'


@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.css']
})
export class QuillEditorComponent implements OnInit {
  @Input('patchValues') patchValues: any;
  @Input('editorHeight') editorHeight: any;

  @Output() emailDocId = new EventEmitter();
  public editor;
  editorText: string;
  editorForm: FormGroup;
  blured = false;
  focused = false;
  modules = {};

 // AlignClass = Quill.import('attributors/class/align');
  AlignStyle = Quill.import('attributors/style/align');

//BackgroundClass = Quill.import('attributors/class/background');
BackgroundStyle = Quill.import('attributors/style/background');

//ColorClass = Quill.import('attributors/class/color');
ColorStyle = Quill.import('attributors/style/color');

//DirectionClass = Quill.import('attributors/class/direction');
DirectionStyle = Quill.import('attributors/style/direction');

//FontClass = Quill.import('attributors/class/font');
FontStyle = Quill.import('attributors/style/font');

//SizeClass = Quill.import('attributors/class/size');
SizeStyle = Quill.import('attributors/style/size');

FormatList = Quill.import('formats/list');
FormatUnderline = Quill.import('formats/underline');
InlineCode = Quill.import('formats/code');
  quillTextEditor: any;


constructor(
  private createService: CreateService,
  private afs: AngularFirestore
) {
    this.modules = {
      // 'emoji-shortname': true,
      // 'emoji-textarea': false,
      // 'emoji-toolbar': true,
      blotFormatter: {
        // empty object for default behaviour.
      },
      'toolbar': {
        container: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],

          ['clean'],                                         // remove formatting button

         ['link', 'image', 'video']                         // link and image, video
        //  ['emoji'],
        ],
        // handlers: { 'emoji': function () { } },

      }
    }

  }

  ngOnInit(): void {
   // console.log('PatchValues', this.patchValues);

    this.editorForm = new FormGroup({
      'editor': new FormControl(null)
    })
    if(this.patchValues){
    //  console.log('PatchValues', this.patchValues);
      this.editorForm.patchValue(this.patchValues);
    }
    this.patchValueFn();
// Quill.register(this.AlignClass, true);
Quill.register(this.AlignStyle, true);

// Quill.register(this.BackgroundClass, true);
Quill.register(this.BackgroundStyle, true);

// Quill.register(this.ColorClass, true);
Quill.register(this.ColorStyle, true);

// Quill.register(this.DirectionClass, true);
Quill.register(this.DirectionStyle, true);

// Quill.register(this.FontClass, true);
Quill.register(this.FontStyle, true);

// Quill.register(this.SizeClass, true);
Quill.register(this.SizeStyle, true);

// Quill.register(this.FormatList, true);
Quill.register(this.FormatUnderline, true);

  }


  onEditorCreated(event) {
  //  console.log('event', event);
    this.editor = event;
   }

  patchValueFn(){
  //  console.log('PATCH VALUES', JSON.parse(this.patchValues));
    let f = JSON.parse(this.patchValues);
     this.editorForm.controls['editor'].patchValue(f);
  }

  changeEditor(event: EditorChangeContent | EditorChangeSelection){
   // console.log('event', event);
    this.editorText = event['editor']['root']['innerHTML'];
  }

  onSubmit(){
   // console.log('event',  this.editorForm.get('editor').value);
    const d = this.editorForm.get('editor').value;

  //   sessionStorage.setItem('EmailDocId', JSON.stringify(id));
    this.emailDocId.emit(d)
  }

  generate(){
  }
}
