import { isDataSource } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, take, pluck } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from './services/read.service';
import { SEOService } from './services/seo.service';
import { PrivacyNotificationDialogComponent } from './shared/privacy-notification-dialog/privacy-notification-dialog.component';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // title = `CSC ${GlobalConstants.branchNameTitleCase}`;


  initialVisit = 'true';
  policyDate: any;
  policyMonthNumber: any;
  policyYear: any;
  policyMonthName: any;
  storedPolicyYear: any;
  storedPolicyMonthNumber: any;
  storedPolicyDate: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private readService: ReadService,
    private seoService: SEOService,
    private route: ActivatedRoute
  ) {

  }
  ngOnInit() {




    this.storedPolicyYear = localStorage.getItem('PolicyYear');
    this.storedPolicyMonthNumber = localStorage.getItem('PolicyMonth');
    this.storedPolicyDate = localStorage.getItem('PolicyDate');

    this.fetchPrivacyPolicyDateFn();
    //  this.createService.setPageTitleFn('CSC USA');
    // const tag = document.createElement('script');
    // tag.src = 'https://www.youtube.com/iframe_api';
    // document.body.appendChild(tag);
  }

  async fetchPrivacyPolicyDateFn() {
    const pDate = await this.readService.returnPromiseWhereFn(
      'cscBranchDirectory/corporate/legal',
      'docId',
      'privacyPolicy'
    );
    pDate.forEach((e) => {
     //   console.log('Policy Date', e);
      this.policyDate = e.policyDate;
      this.policyMonthNumber = e.policyMonthNumber;
      this.policyMonthName = e.policyMonthName;
      this.policyYear = e.policyYear;
    });
    const storedPolicyDate = `${this.storedPolicyMonthNumber}, ${this.storedPolicyDate}, ${this.storedPolicyYear}`;
    //  console.log('storedPolicyDate', storedPolicyDate);
    const currentPolicyDate = `${this.policyMonthNumber}, ${this.policyDate}, ${this.policyYear}`;
    //  console.log('currentPolicyDate', currentPolicyDate);
    if (storedPolicyDate !== currentPolicyDate) {
      this.privacyDialogFn();
    }
  }

  privacyDialogFn() {
    const dialogRef = this.dialog.open(PrivacyNotificationDialogComponent, {
      panelClass: 'editDialog',
      data: {
        policyDate: `Privacy Policy Updated: ${this.policyMonthName} ${this.policyDate}, ${this.policyYear}`,
        class: 'newsletterSignUp',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        //  console.log(res.data);
        localStorage.setItem('PolicyMonth', this.policyMonthNumber);
        localStorage.setItem('PolicyDate', this.policyDate);
        localStorage.setItem('PolicyYear', this.policyYear);
      });
  }
}

