
<ng-container *ngIf="patchValues">
  <app-quill-editor
  [editorHeight]="editorHeight"
  [patchValues]="patchValues"
  (emailDocId)="textReturnedFn($event)"
></app-quill-editor>
</ng-container>
<ng-container *ngIf="!patchValues">
  <app-quill-editor
  [editorHeight]="editorHeight"
  (emailDocId)="textReturnedFn($event)"
></app-quill-editor>
</ng-container>
