import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../auth/admin.guard';
import { FederatedLoginComponent } from '../auth/federated-login/federated-login.component';
import { IsSignedInGuard } from '../auth/is-signed-in.guard';
import { SignupComponent } from '../auth/signup/signup.component';
import { VerifiedEmployeeGuard } from '../auth/verified-employee.guard';
import { sectionMetadata } from '../public/sections-metadata';
import { MainNavComponent } from './main-nav/main-nav.component';
const routes: Routes = [
   {path: 'admin',
   loadChildren: () => import ('../admin/admin.module').then(m => m.AdminModule),
   canActivate: [AdminGuard]
   },
   {path: 'login',
   loadChildren: () => import ('../auth/auth.module').then(m => m.AuthModule)},
   {path: 'privacy-policy',
   loadChildren: () => import ('../public/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)},
   {path: 'staff',
   data: sectionMetadata.teamPage,
   loadChildren: () => import ('../public/public-staff/public-staff.module').then(m => m.PublicStaffModule)},
   {path: 'bulletin-board',
   data: sectionMetadata.bulletinBoardPage,
   loadChildren: () => import ('../public/blog/blog.module').then(m => m.BlogModule)},
   {path: 'faq',
   data: sectionMetadata.faqPage,
   loadChildren: () => import ('../public/public-faq/public-faq.module').then(m => m.PublicFaqModule)},
   {path: 'departments',
   loadChildren: () => import ('../public/public-departments/public-departments.module').then(m => m.PublicDepartmentsModule)},
   {path: 'events',
   data: sectionMetadata.eventsPage,
   loadChildren: () => import ('../public/public-events/public-events.module').then(m => m.PublicEventsModule)},
   {path: 'positions',
   loadChildren: () => import ('../public/available-positions/available-positions.module').then(m => m.AvailablePositionsModule)},
   {path: 'venues',
   loadChildren: () => import ('../public/public-venues/public-venues.module').then(m => m.PublicVenuesModule)},
   {path: 'account',
   canActivate: [IsSignedInGuard],
   loadChildren: () => import ('../public/member-profile/member-profile.module').then(m => m.MemberProfileModule)},
   {path: 'employees',
   loadChildren: () => import ('../auth/auth-routing.module').then(m => m.AuthRoutingModule)},
   {path: 'getting-started',
   loadChildren: () => import ('../public/public-how-to-videos/public-how-to-videos.module').then(m => m.PublicHowToVideosModule)}
  ];


// const routes: Routes = [
//    {path: 'admin',
//    canLoad: [AdminGuard],
//    loadChildren: () => import ('../admin/admin.module').then(m => m.AdminModule)
//    },
//    {path: 'login',
//    loadChildren: () => import ('../auth/auth.module').then(m => m.AuthModule)}
//   ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class NavRoutingModule {
  static components = [MainNavComponent];
 }
