import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {
  documentTitle: any;
  documentMessage: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < InfoDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.documentTitle = this.data?.documentTitle;
    this.documentMessage = this.data?.documentMessage;
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
