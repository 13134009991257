<p>Checking Email...</p>


<ng-container *ngIf="isAlreadyRegistered">
  <div class="verificationCompleteWrapper">
<p>
  Hi {{personLegalNameFirst}}, you're already registered. We'll redirect you to Sign In...
</p>
</div>
</ng-container>

