
<div class="headerSection">
  <div class="headerImage">
    <img src="../../../assets/img/sofi_1200.jpg" alt="SoFi Satium - Inglewood, Los Angeles">
  </div>
</div>

<div class="joinCouncilman">
  <p class="intro">
    JOIN COUNCILMEMBER MARQUEECE HARRIS-DAWSON, WEST ADAMS WORKSOURCE CENTER, CONTEMPORARY SERVICES CORPORATION, AND LEGENDS FOR:
  </p>

  <p class="sofiStadium">
   <img src="../../../assets/img/logos/sofi_logo.png" alt="">
  </p>
  <p class="sofiStadium">
JOB FAIR  </p>

<p class="outro">
  Join us to learn more about the exciting employment opportunities in security, ushering, guest services, culinary, retail, and concessions at Inglewood's SOFI Stadium
</p>
</div>

<footer>
  <div class="dateLocation">
    <div class="dateTime">
       <p>JULY 10, 2021 - 10AM - 3PM</p>

    <p>BALDWIN HILLS CRENSHAW MALL - SEARS BOWL</p>
    <p></p>
    </div>
    <div class="address">
      <p>3650 W. MARTIN LUTHER KING JR. BLVD</p>
      <p>LOS ANGELES, CA 90008</p>
    </div>

     <div class="googleMapWrapper">
    <google-map height="250px"  [options]="options">
      <map-marker
      #somemarker="mapMarker"
      *ngFor="let marker of markers"
      [position]="marker.position"
      [label]="marker.label"
      [title]="marker.title"
      [options]="marker.options"
      (mapClick)="openInfo(somemarker, marker.info)"

    >
    </map-marker>
    <map-info-window>
      <div style="color: black;">
        <p><strong>SoFi Stadium Job Fair</strong></p>
        <p>3650 W. Martin Luther King JR., BLVD</p>
        <p>Los Angeles, CA 90008</p>
        <p>Schedule an appointmet now!</p>
      </div>

    </map-info-window>

    </google-map>
  </div>
  </div>


  </footer>


<div class="pageWrapper">
  <p class="scheduleInterview">
  SCHEDULE AN INTERVIEW
  </p>
    <ng-container *ngIf="formConfigUser">
    <app-dynamic-form
      (submittedFormData)="receivedDynamicDataFn($event)"
      [f]="formConfigUser"
    >
    <span class="btnLabel">Submit</span>
    </app-dynamic-form>
  </ng-container>



<div class="messagesSection">
  <ng-container *ngIf="selectedCompany">

<div class="thankYouSection">
  <p>Thank you for your interest in {{selectedCompany | uppercase}}</p>
  <p>You will now be redirected to complete your application in <span class="count">{{count}}</span>  </p>
  </div>
</ng-container>

<ng-container *ngIf="notRedirected">
  <div class="redirectSection">
    <p>If you are not automatically redirected, please click </p>
    <div>
      <span><a mat-raised-button class="greenBtn" href="{{redirectUrl}}">Redirect Now</a></span>
    </div>
  </div>
</ng-container>
</div>

</div>
<div class="logoSection">
  <div class="logos">
  <div>
    <img src="../../../assets/img/logos/Legends-Logo.jpg" alt="">
  </div>
  <div>
    <img src="../../../assets/img/logos/employee_owned.jpeg" alt="">
  </div>
  <div>
    <img src="../../../assets/img/logos/west_adams.png" alt="">
  </div>
  <div>
    <img src="../../../assets/img/logos/aadap.png" alt="">
  </div>
  <div>
    <img src="../../../assets/img/logos/lacitycouncil.png" alt="">
  </div>
  <div>
    <img src="../../../assets/img/logos/baldwin_hills_crenshaw.gif" alt="">
  </div>
</div>
</div>


  <div class="base">
  </div>
