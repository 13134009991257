import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { SnackbarService } from '../services/snackbar.service';
import { AuthService } from './auth.service';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from '../services/read.service';
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanLoad {
  user$: Observable<any>;
  adminArray: any[];

  constructor(
    private authService: AuthService,
    private readService: ReadService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private snackbarService: SnackbarService) {}

  canActivate() {
    this.user$ = this.afAuth.authState;
   // console.log('USER$', this.user$);
    return this.afAuth.authState.pipe(
        take(1),
        switchMap(async (authState) => {
         // console.log('authState', authState);
          this.adminArray = [];
         const u = await this.readService.returnPromiseFieldOrderBy(
          `${GlobalConstants.rootCollectionAndBranchDoc}/branchAuthUsers`,
            'personEmail',
            'asc'
          )
        //  console.log('U', u);

          u.forEach(e => {
            this.adminArray.push(e);
          })
        //  console.log('ARRAY', this.adminArray);
          const isLoggedIn = this.authService.isLoggedIn;
         //   console.log('ADMIN', isLoggedIn);
            const roles = ['corpDBA', 'corpSeniorExecutive', 'corpAdministrator'];
          //  console.log('AuthState', authState);
            if (isLoggedIn) { // check are user is logged in
             //   console.log('AuthState', authState.email);

              const  authUser = JSON.parse(sessionStorage.getItem('AuthUser'));

             //  console.log('ARRAY', this.adminArray);
            //   console.log('authUser', authUser.email);
                const authEmail = authUser.email;
                if (this.adminArray.includes(authEmail) && isLoggedIn)
                {
                //  console.log('Auth Email', authEmail)
                  return true;
                } else {
                //  console.log('NOT PERMITTED')
                  const snackClass = ['snackError'];
                  const message = 'You are not permitted to access this page.';
                  this.snackbarService.openSnackBar(message, snackClass);
                  this.router.navigate(['/faq/list'])
                  return false;
                }
            } else {
                this.router.navigate(['/events']);
                return false;
            }
        }),
    );
}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.user$ = this.afAuth.authState;
      return this.user$.pipe(map(user => {
        if (user) {return true;
         }else{
         const message = `Access Denied.`;
        const snackClass = ['snackError'];
        this.router.navigate(['/'])
              .then(() => this.snackbarService.openSnackBar(message, snackClass));
        return false;
      }
      }))
  }
}
