import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CreateService } from 'src/app/services/create.service';
import { DynamicFormConfigService } from 'src/app/services/dynamic-form-config.service';
import { ReadService } from 'src/app/services/read.service';
import { GlobalConstants} from '../../global-variables';
@Component({
  selector: 'app-legends-sofi',
  templateUrl: './legends-sofi.component.html',
  styleUrls: ['./legends-sofi.component.css']
})
export class LegendsSofiComponent implements OnInit {
  isLinear = false;
  formConfigUser: any;
  list: Observable<any[]>;
  constructor(
    private formConfigService: DynamicFormConfigService,
    private afs: AngularFirestore,
    private readService: ReadService,
    private createService: CreateService,


  ) { }

  ngOnInit(): void {
  //  console.log('globalBranchDocId', GlobalConstants.globalBranchDocId);

    this.formConfigDataFn();
    this.list = this.readService.returnRecordsOrderByFn(
      `cscBranchDirectory/${GlobalConstants.globalBranchDocId}/sofiJobFair`,
'timeSlot',
'asc'
    )
  }

  async formConfigDataFn(){
    this.formConfigUser = await this.formConfigService.sofiLegendsApplicationFn();
  //  console.log('CONFIG', this.formConfigUser);
    //  this.createControlsFn(this.formConfigUser);
    // this.showForm = true;
  }


  receivedDynamicDataFn(e: any){
  //  console.log('PROFILE EVENT', e);
    const selectedJob = e.legendsJobOptions.value;
    const timeSlot = e.legendsSofiTimeSlots.value;
const docId = this.afs.createId();
const documentPath = `cscBranchDirectory/${GlobalConstants.globalBranchDocId}/sofiJobFair`;
    const formData = {
      ...e,
      timeSlot,
      selectedJob,
      docId,
      company: 'legends'
    }
  //  console.log('formData', e);
  //  console.log('documentPath', documentPath);

    try {
      this.createService.createItRecordFn(
        documentPath,
        docId,
        formData
      )
    } catch (e) {
    //  console.log('ERROR MSG', e);

    }


//  this.readService.returnRecordsOrderByFn(
//   `cscBranchDirectory/${branchSpaceReplace}/onlineTrainingEmailRecipients`,
//   'docId',
//   'asc'
//  ).subscribe(d => {
//    console.log('EMPS', d);
//    for (let x of d){
//      this.branchStaffId = x.docId;
//    }
//    console.log('Branch Staff Id', this.branchStaffId);
//  });

    // this.updateService.updateRecordFn(
    //   'cscOnlineTrainingAccounts',
    //   `${this.authService?.userProfile$?.uid}`,
    //   d
    // );
  }
}
