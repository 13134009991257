

<form  [formGroup]="editorForm" (ngSubmit)="onSubmit()">
<quill-editor
[sanitize]="false"
class="qe"
[styles]="{height: editorHeight}"
formControlName="editor"
(onEditorChanged)="changeEditor($event)"
[modules]="modules"
></quill-editor>
<div class="sendBtnWrapper">
<button mat-raised-button class="cscButtons">Submit</button>
</div>
</form>




<!--
<div class="output">
  <p [innerHTML]="editorText">

  </p>
</div>
<div class="ql-container ql-snow" style="border-width: 0;">
  <div class="ql-editor" [innerHTML]="editorText">
  </div>
</div> -->
<div class="testData">
  <h4>Lorem Text</h4>
</div>

Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint ullam, maiores deleniti neque nisi quis explicabo suscipit, possimus officia earum minus repellat sit nulla! Beatae, at. Eius reprehenderit voluptates, et suscipit beatae numquam eveniet quasi a facilis, aliquam illum! Recusandae quam eveniet incidunt quos omnis totam! Adipisci quaerat eius vel?
