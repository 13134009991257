import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-request-dialog',
  templateUrl: './confirm-request-dialog.component.html',
  styleUrls: ['./confirm-request-dialog.component.css']
})
export class ConfirmRequestDialogComponent implements OnInit {
  eventTitle: any;
  eventVenue: any;
  eventDuration: any;
  startDate: any;
  class: any;
  eventId: any;
  formConfig: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < ConfirmRequestDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.eventTitle = this.data?.eventTitle;
    this.eventVenue = this.data?.eventVenue;
    this.eventDuration = this.data?.eventDuration;
    this.startDate = this.data?.startDate;
    this.class = this.data?.class;
    this.formConfig = this.data?.formConfig;

  }

  receivedDynamicDataFn(e: any){
   //('eventId', this.eventId);

   try {
      this.dialogRef.close({data: e});
     } catch (error) {
      //   console.log('Error', error);
     }
  }
}
