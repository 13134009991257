import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackbarService } from '../services/snackbar.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {

  constructor(
   private authService: AuthService,
   private router: Router,
   public afAuth: AngularFireAuth,
   private snackbarService: SnackbarService
  ){}

   canActivate(
     route: ActivatedRouteSnapshot,
     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
       const isLoggedIn = this.authService.isLoggedIn;

       if(isLoggedIn) {
         // window.alert('Access Denied, Login is Required to Access This Page!')
         return true;
       } else {
        //  const snackClass = ['snackError'];
        //  const message = 'You are not authorized to view this page, please sign in.';
        //  this.snackbarService.openSnackBar(message, snackClass);
        this.afAuth.signOut();
        sessionStorage.clear();
         this.router.navigate(['login']);
         return false;
       }

   }
 }
