<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->
  <mat-sidenav
    *ngIf="isPhone || isTablet"
    #sitesidenav
    class="sidenav"
    fixedInViewport
    closed
    position="end"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <ng-container *ngIf="authService.isLoggedIn as user; else noUser">

           <a
            mat-list-item
            color="primary"
            [routerLink]="['account']"
            (click)="sitesidenav.close()"
          >
          My Account</a
          >
          <ng-container *ngIf="authService.isAdmin || isAdmin">
            <a
              class=""
              mat-list-item
              routerLink="/admin"
              (click)="sitesidenav.close()"
              >Admin</a
            >
          </ng-container>
          <hr>
        <ng-container *ngFor="let x of loggedInMainNavMenuItems">


          <a
            mat-list-item
            color="primary"
            [routerLink]="x.link"
            (click)="sitesidenav.close()"
          >
            {{ x.name }}</a
          >
        </ng-container>
      <hr>
        <a
          mat-list-item
          (click)="afAuth.signOut()"
          (click)="sitesidenav.close()"
          >Logout</a
        >
      </ng-container>
      <ng-template #noUser>
        <ng-container *ngFor="let x of notLoggedInMainNavMenuItems">
          <a
            class=""
            mat-list-item
            [routerLink]="x.link"
            (click)="sitesidenav.close()"
          >
            {{ x.name }}
          </a>
        </ng-container>
        <a
          class=""
          mat-list-item
          [routerLink]="['/employees']"
          (click)="sitesidenav.close()"
        >
          Sign In | Register</a
        >
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- TOP TOOLBAR-->
    <mat-toolbar color="primary">
      <ng-container *ngIf="authService.isAdmin|| isAdmin">
        <div class="adminToggle">
        <button
        type="button"
        aria-label="Toggle admin sidenav"
        mat-icon-button
        (click)="toggleAdminFn()"
      >
        <mat-icon class="mobileMenuBtn" aria-label="Side nav toggle icon"
          >menu</mat-icon
        >
      </button>
        </div>
        </ng-container>

      <ng-container *ngFor="let x of miscImages | async">
 <div class="cscNavLogo" routerLink="/home">
        <img
          class="navLogo"
          [src]="x.imageUrl"
          alt=""
        />
        <div>
          <span class="logo">{{ branchTitle }}</span>
        </div>
      </div>
      </ng-container>

      <span class="fill-space"></span>
      <button
        type="button"
        aria-label="Toggle sitesidenav"
        mat-icon-button
        (click)="sitesidenav.toggle()"
        *ngIf="isPhone || isTablet"
      >
        <mat-icon class="mobileMenuBtn" aria-label="Side nav toggle icon"
          >menu</mat-icon
        >
      </button>
      <div class="mainNav" *ngIf="isLaptop || isDesktop || isLargeDesktop">
        <ng-container *ngIf="authService.isLoggedIn as user; else noUser">
          <ng-container *ngFor="let x of loggedInMainNavMenuItems">
            <a class="" mat-button [routerLink]="x.link">
              {{ x.name }}
            </a>
          </ng-container>
          <!-- <ng-container *ngIf="isAdmin">
            <a class="p-01-01 m-01-01" mat-button [routerLink]="['/admin']"
              >Admin</a
            >
          </ng-container> -->
          <button mat-button [matMenuTriggerFor]="accountMenu">
            <span class="material-icons"> account_circle </span>
          </button>
          <mat-menu #accountMenu="matMenu" yPosition="below">
            <button mat-menu-item [routerLink]="['/account']">
              My Account
            </button>
            <ng-container *ngIf="authService.isAdmin || isAdmin">
              <button mat-menu-item [routerLink]="['/admin']">
                Admin
              </button>
              </ng-container>
            <button mat-menu-item (click)="authService.logout()">Logout</button>
          </mat-menu>
        </ng-container>


        <ng-template #noUser>
          <ng-container *ngFor="let x of notLoggedInMainNavMenuItems">
            <a class="" mat-button [routerLink]="x.link">
              {{ x.name }}
            </a>
          </ng-container>
          <a class="p-01-01 m-01-01" mat-button [routerLink]="['/employees']">
            Sign In | Register</a
          >
        </ng-template>
      </div>
    </mat-toolbar>
    <!-- TRANSCLUSION -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
