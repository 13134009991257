<div class="pageWrapper">
<div class="pageTitle">
  <h1>Our <span class="cscGoldText">Venues</span></h1>
</div>
  <div class="cardGrid fadeIn">
    <div *ngFor="let x of venueData | async" class="card">
      <a [routerLink]="['/venues/i', x.docId]">
        <div class="venueWrapper">
          <div class="venueName">
            <p>{{ x.venueName }}</p>
          </div>
          <div class="venueImage">
            <img class="navImg" [src]="x.imageUrl" />
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
