<div class="formWrapper">
  <ng-container *ngIf="formConfig">
<app-dynamic-form
(submittedFormData)="submittedFormData($event)"
[f]="formConfig"
[class]="formCSSClassName"
>
<span class="btnLabel">Verify</span>
</app-dynamic-form>
</ng-container>
</div>

<div class="res">
  <h3>Result:</h3>
<div class="resData">
 <h4>Status:</h4>
 <p>
   {{resStatus ? 'TRUE': 'FALSE'}}
 </p>
 <h4>Message:</h4>
 <p>
   {{resMessage}}
 </p>
 <h4>Data:</h4>
<div >

<div class="resDataObj" *ngFor="let x of resData">
  <div>
    <p>
  Name:
</p>
    <p class="bold">
   {{x.FirstName}}   {{x.LastName}}
</p>
  </div>

  <div>
    <p>
      Employee Number:
    </p>
    <p class="bold">
   {{x.EmpNumber}}
    </p>
  </div>

<div>
  <p>
    Job Title:
  </p>
  <p class="bold">
 {{x.JobTitle}}
  </p>
</div>

<div>
  <p>
    Phone:
  </p>
  <p class="bold">
 {{x.EmpPhone1}}
  </p>
</div>
<div>
  <p>
  Email:
</p>
<p class="bold">
 {{x.Email}}
</p>
</div>

</div>

</div>
</div>

</div>
