<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography permitted-or-not-dialog">
  <div>
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-card fxFlex="90">
        <mat-card-content>
          <div class="images-container">
            <div class="sub-section-wrapper">
              <!-- <div class="faqTitle">
                <h4>Click 'Confirm' to <span class="bold">PERMANENTLY DELETE:</span> </h4>
              </div> -->
              <div>
                <ng-container *ngIf="documentTitle">
                  <div class="m-1-0">
                    {{documentTitle}}
                  </div>
                  </ng-container>
              </div>
              <div>
                <ng-container *ngIf="documentMessage">
                  <div style="white-space: pre-line;" class="bold m-1-0">
                    {{documentMessage}}
                  </div>
                  </ng-container>
              </div>
            </div>

          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="closeDialog()" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
