import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-document-dialog',
  templateUrl: './add-document-dialog.component.html',
  styleUrls: ['./add-document-dialog.component.css']
})
export class AddDocumentDialogComponent implements OnInit {
  formConfig: any;
  patchValues: any;
  class: any;
  dialogClass: any;
  docId: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < AddDocumentDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.formConfig = this.data.formConfig;
    this.patchValues = this.data?.patchValues;
    this.class = this.data?.class;
    this.dialogClass = this.data?.dialogClass;
    // this.firestoreRecordPath = this.data.firestoreRecordPath;
    this.docId = this.data.docId;
  }


  receivedDynamicDataFn(e: any){
    //  console.log('E', e);
     this.dialogRef.close({data: e});
   }
}
