import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnitedStatesListService {

  constructor() { }

  usStatesListFn(){

    return  [
      {
       optionName: 'Alabama',
       value: 'alabama'
    }, {
      optionName: 'Alaska',
       value: 'alaska'
    },
    {
optionName: 'Arizona',
       value: 'arizona'
    },
    {
      optionName:'Arkansas' ,
      value: 'arkansas'
    },
    {
     optionName: 'California' ,
       value: 'california'
    },
    {
      optionName: 'Colorado',
       value: 'colorado'
    },
    {
      optionName: 'Connecticut',
      value: 'connecticut'
    },
    {
      optionName: 'Delaware',
       value: 'delaware'
    },
    {
      optionName: 'District of Columbia',
      value: 'district of columbia'
    },
    {
      optionName: 'Florida',
      value: 'florida'
    },
    {
      optionName: 'Georgia',
      value: 'georgia'
    },
    {
      optionName:'Hawaii' ,
      value: 'hawaii'
    },
    {
      optionName: 'Idaho',
      value: 'idaho'
    },
    {
      optionName: 'Illinois',
      value: 'illinois'
    },
    {
      optionName:'Indiana' ,
      value: 'indiana'
    },
    {
      optionName: 'Iowa' ,
       value: 'iowa'
    },
    {
      optionName: 'Kansas',
       value: 'kansas'
    },
    {
      optionName: 'Kentucky',
      value: 'kentucky'
    },
    {
      optionName: 'Louisiana' ,
      value: 'louisiana'
    },
    {
      optionName: 'Maine',
      value: 'maine'
    },
    {
      optionName:'Maryland' ,
      value: 'maryland'
    },
    {
      optionName:'Massachusetts' ,
      value: 'massachusetts'
    },
    {
      optionName: 'Michigan',
      value: 'michigan'
    },
    {
      optionName: 'Minnesota',
       value: 'minnesota'
    },
    {
      optionName:  'Mississippi',
       value: 'mississippi'
    },
    {
      optionName: 'Missouri',
      value: 'missouri'
    },
    {
      optionName: 'Montana',
       value: 'montana'
    },
    {
      optionName: 'Nebraska',
       value: 'nebraska'
    },
    {
      optionName: 'Nevada' ,
       value: 'nevada'
    },
    {
      optionName: 'New Hampshire',
       value: 'new hampshire'
    },
    {
      optionName: 'New Jersey',
      value: 'new jersey'
    },
    {
      optionName: 'New Mexico',
      value: 'new mexico'
    },
    {
      optionName: 'New York',
      value: 'new york'
    },
    {
      optionName: 'North Carolina' ,
       value: 'north carolina'
    },
    {
      optionName: 'North Dakota',
      value: 'north dakota'
    },
    {
      optionName: 'Ohio' ,
       value: 'ohio'
    },
    {
      optionName: 'Oklahoma',
      value: 'oklahoma'
    },
    {
      optionName: 'Oregon',
       value: 'oregon'
    },
    {
      optionName: 'Pennsylvania' ,
      value: 'pennsylvania'
    },
    {
      optionName: 'Rhode Island',
      value: 'rhode island'
    },
    {
      optionName: 'South Carolina',
      value: 'south carolina'
    },
    {
      optionName: 'South Dakota',
      value: 'south dakota'
    },
    {
      optionName:  'Tennessee',
       value: 'tennessee'
    },
    {
      optionName: 'Texas',
      value: 'texas'
    },
    {
      optionName: 'Utah',
      value: 'utah'
    },
    {
      optionName: 'Vermont' ,
      value: 'vermont'
    },
    {
      optionName: 'Virginia',
       value: 'virginia'
    },
    {
      optionName: 'Washington',
      value: 'washington'
    },
    {
      optionName: 'West Virginia',
       value: 'west virginia'
    },
    {
      optionName: 'Wisconsin',
       value: 'wisconsin'
    },
    {
      optionName: 'Wyoming',
      value: 'wyoming'
    }


    ]
  }

}
