import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CreateService } from 'src/app/services/create.service';
import { DynamicFormConfigService } from 'src/app/services/dynamic-form-config.service';
import { ReadService } from 'src/app/services/read.service';
import { GlobalConstants} from '../../global-variables';

@Component({
  selector: 'app-csc-sofi',
  templateUrl: './csc-sofi.component.html',
  styleUrls: ['./csc-sofi.component.css']
})
export class CscSofiComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  isLinear = false;
  formConfigUser: any;
  list: Observable<any[]>;
  selectedCompany: string;
  count = 5;
  notRedirected =false;
  timeout;
  markers = []

  redirectUrl: any;
  options: google.maps.MapOptions = {
    center: {lat: 34.0100944664681, lng: -118.33726403297537},
    zoom: 16
  };
  mapHeight: "250px";
  infoContent = '';

  constructor(
    private formConfigService: DynamicFormConfigService,
    private afs: AngularFirestore,
    private readService: ReadService,
    private createService: CreateService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document


  ) { }

  ngOnInit(): void {
  //  console.log('globalBranchDocId', GlobalConstants.globalBranchDocId);

    this.formConfigDataFn();
    this.list = this.readService.returnRecordsOrderByFn(
      `cscBranchDirectory/${GlobalConstants.globalBranchDocId}/sofiJobFair`,
'timeSlot',
'asc'
    )
    this.addMarker();
  }

  addMarker() {
    this.markers.push({
      position: {
        lat: 34.0100944664681,
        lng: -118.33726403297537,
      },
      label: {
        color: 'red',
        text: 'SoFi Stadium Job Fair ',
      },
      info: "SoFi Stadium Job Fair",
      title: 'SoFi Stadium Job Fair',
      options: { animation: google.maps.Animation.DROP },
    })
  }

  openInfo(marker: MapMarker, content) {
    this.infoContent = content;
    this.infoWindow.open(marker)
  }

  async formConfigDataFn(){
    this.formConfigUser = await this.formConfigService.userProfileFn('cscBranchDirectory', 'branchName', 'asc');
   // console.log('CONFIG', this.formConfigUser);
    //  this.createControlsFn(this.formConfigUser);
    // this.showForm = true;
  }

countdownFn(url){
 this.timeout = setInterval(() => {
    if (this.count > 1) {
      this.count -= 1;
      setTimeout(() => {
        this.notRedirected = true;
      }, 6000);
      // this.redirectUrl = url;
     //  console.log('REDIRECT URL', url)
    } else {
      clearInterval(this.timeout);
      this.document.location.href = url;
      this.selectedCompany = '';
      this.notRedirected = false;
      this.redirectUrl = url;
    }
  }, 1000);
}
redirectUrlFn(url){
  this.document.location.href = url;
}
  receivedDynamicDataFn(e: any){
  //  console.log('PROFILE EVENT', e);
    const selectedJob = e.jobOptions.value;
  //  console.log('selectedJob', selectedJob);
    const timeSlot = e.sofiTimeSlots.value;
const docId = this.afs.createId();
const documentPath = `cscBranchDirectory/${GlobalConstants.globalBranchDocId}/sofiJobFair`;
const n = selectedJob.endsWith('CSC');
    // console.log('N', n);
if (selectedJob.endsWith('CSC')){
 // console.log('selectedJob', selectedJob);
  this.selectedCompany = 'csc';
  this.formConfigUser = false;
  this.countdownFn('https://hportal.schedulingsite.com/verifyemail/OWJmYzFjYzMtMGM2OC00OGViLWI5MDgtMzdkN2JkMzJjZDM0');
} else if (selectedJob.endsWith('Legends')){
  this.selectedCompany = 'legends';
  this.formConfigUser = false;

  this.countdownFn('https://recruiting.ultipro.com/LEG1003LGNDS/JobBoard/7cb7e3f4-053b-4339-af7b-1c62dc39bfc9/?q=&o=distance&w=Inglewood%2C+California%2C+USA&wc=-118.35673904293046%2C33.96178021984906&we=-118.45674%2C34.06178%7C-118.25674000000001%2C33.861779999999996&wpst=2');
}
    const formData = {
      ...e,
      timeSlot,
      selectedJob,
      docId,
      company: this.selectedCompany
    }
   // console.log('formData', e);
   // console.log('documentPath', documentPath);
    try {
      this.createService.createItRecordFn(
        documentPath,
        docId,
        formData
      )
    } catch (e) {
    //  console.log('ERROR MSG', e);

    }


//  this.readService.returnRecordsOrderByFn(
//   `cscBranchDirectory/${branchSpaceReplace}/onlineTrainingEmailRecipients`,
//   'docId',
//   'asc'
//  ).subscribe(d => {
//    console.log('EMPS', d);
//    for (let x of d){
//      this.branchStaffId = x.docId;
//    }
//    console.log('Branch Staff Id', this.branchStaffId);
//  });

    // this.updateService.updateRecordFn(
    //   'cscOnlineTrainingAccounts',
    //   `${this.authService?.userProfile$?.uid}`,
    //   d
    // );
  }
}
