import { Subject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
// import { User } from './user.model';

import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/app';
import { SnackbarService } from '../services/snackbar.service';
import { ReadService } from '../services/read.service';
import { GlobalConstants } from 'src/app/global-variables';
import { UpdateService } from '../services/update.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  catchPhraseMatches = false;
  catchPhraseDoesntMatch = false;
  notAuth = false;
  displayLoginForm = true;
  passwordErrorMsg = false;
  user: firebase.User;

  isAuth = false;
  isAdmin = false;
  loggedInUid;
  userEmail: string;
  currentUserInfo: any;
  emailVerified: boolean;
  personLegalNameFirst: any;
  personEmployeeNumber: any;
  personLegalNameLast: any;
  personRole: any;
  isSuperAdmin: boolean;
  loggedInUserEmail: any;
  microsoftProviderExits: boolean;

  constructor(
    private fireAuth: AngularFireAuth,
    public afAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private readService: ReadService,
    public snackbarService: SnackbarService,
    private updateService: UpdateService
  ) {
    this.afAuth.onAuthStateChanged((user) => {
      this.user = user;
      this.isAuth = true;
      // console.log('USER', this.user);
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
           // const isAdmin = true;
            //   console.log(idTokenResult.claims);
          }
        });
        sessionStorage.setItem('AuthUser', JSON.stringify(user));
        const appleSubstitutionTest = 'monica.geller.csc@gmail.com';
        const cscEmail = user.email.endsWith('@csc-usa.com');
        const apexEmail = user.email.endsWith('@apexsgi.com');

        console.log('cscEmail', cscEmail);

        if (cscEmail || apexEmail) {
          const providerDataArray = user.providerData;
          console.log('providerArray', providerDataArray);          
          const providerArray = [];     
                providerDataArray.forEach(e => {
                providerArray.push(e.providerId);
           });

           console.log('Provider Array', providerArray);  
    
         if(providerArray.includes('microsoft.com')){
            this.microsoftProviderExits = true;  
            sessionStorage.setItem('MicrosoftProviderExits', JSON.stringify(true));

         }  else {
          this.microsoftProviderExits = false;
          sessionStorage.setItem('MicrosoftProviderExits', JSON.stringify(false));
        }
        } 


      
        this.loggedInUid = user.uid;
        // this.userEmail = appleSubstitutionTest;
        this.userEmail = user.email;

        //  console.log(user.email);
        this.emailVerified = user.emailVerified;
        this.user = user;

        sessionStorage.setItem('LoggedInUserEmail', JSON.stringify(this.userEmail));
        JSON.parse(sessionStorage.getItem('LoggedInUserEmail'));
        this.loggedInUserDataFn(this.userEmail);
        this.checkIfBranchAuthAdminFn(this.userEmail);
        //  this.updateUid(this.userEmail, user.uid);

        // this.checkIsAdmin();
      } else {
        this.isAuth = false;
        sessionStorage.setItem('LoggedInUserEmail', null);
        JSON.parse(sessionStorage.getItem('LoggedInUserEmail'));
      //  this.logout();
      }
    });
  }

  // updateUid(email: string, uid: string){
  //   const ud = {
  //     uid
  //   }
  //  this.updateService.updateRecordFn(
  //     `${GlobalConstants.rootEmployeeCollection}`,
  //     email,
  //     ud
  //   )
  // }

  async checkIfBranchAuthAdminFn(personEmail) {
    const u = await this.readService.returnPromiseWhere(
      `${GlobalConstants.rootCollectionAndBranchDoc}/branchAuthUsers`,
      'personEmail',
      personEmail
    );
    if (!u?.length) {
      this.isAdmin = false;
      this.isSuperAdmin = false;
     // console.log('NOT ADMIN');
    } else {
      const [d] = [...u];
      // console.log('D', d);
      if (d.isAdmin) {
        this.isAdmin = true;
        this.personRole = d.personRole;
      //  console.log('IS ADMIN: YES');
      }
      if (d.isSuperAdmin) {
        this.isSuperAdmin = true;
        this.personRole = d.personRole;
      //  console.log('IS SUPER ADMIN: YES');
      }
    }
  }

  async loggedInUserDataFn(personEmail) {
    const u = await this.readService.returnPromiseWhere(
      `${GlobalConstants.rootEmployeeCollection}`,
      'personEmail',
      personEmail
    );

    u.forEach((e) => {
     // console.log('PROFILE', e);
      (this.personLegalNameFirst = e.personLegalNameFirst),
        (this.personLegalNameLast = e.personLegalNameLast),
        (this.personEmployeeNumber = e.personEmployeeNumber);
      this.personRole = e.personRole;
    });
  }

  async login(email: string, password: string) {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        //  return firebase.auth().signInWithEmailAndPassword(email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });

    const signIn = await this.fireAuth.signInWithEmailAndPassword(
      email,
      password
    );

    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    const userCurrent = firebase.auth().currentUser;
    const link = await userCurrent.linkWithPopup(provider);
    provider.setCustomParameters({
      tenant: 'csc-usa.com',
    });
    const snackClass = ['snackSuccess'];
    const message = `Your account is now linked.`;
    this.snackbarService.openSnackBar(message, snackClass);
    // console.log('LINK CRED', link.credential);
    // console.log('LINK USER', link.user);

    setTimeout(() => {
      // this.catchPhraseMatches = false;
      this.router.navigateByUrl('admin');
    }, 3000);
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('AuthUser'));
    return (user !== null) ? true : false;
  }

  logout() {
    this.afAuth.signOut();
    sessionStorage.setItem('LoggedInUserEmail', null);
    sessionStorage.clear();
    // localStorage.clear();
    this.isAuth = false;
    // const snackClass = ['snackError'];
    // const message = `Signing Out: ${this.userEmail}`;
    // this.snackbarService.openSnackBar(message, snackClass);
    this.router.navigate(['/']);

  }
}
