import { Component, Input, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { GlobalConstants } from 'src/app/global-variables';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { ReadService } from 'src/app/services/read.service';


export class AutoId {
  static newId() {
    // Alphanumeric characters
    const chars = 'abcdefghijklmnopqrstuv0123456789';
    // const chars = 'ABCDEFGHIJKLMNOPQRSTUVabcdefghijklmnopqrstuv0123456789';
    let autoId = '';
    for (let i = 0; i < 50; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    // assert(autoId.length === 20, 'Invalid auto ID: ' + autoId);
    return autoId;
  }
}


@Component({
  selector: 'app-excel-to-json',
  templateUrl: './excel-to-json.component.html',
  styleUrls: ['./excel-to-json.component.css']
})
export class ExcelToJsonComponent implements OnInit {
  @Input() headerNamesArray: any;
  @Input() spreadsheetExample: any;
convertedJSON!: string;
  jsonData: any;
  venueDocId: any;
  venueName: any;
  venueAddressOne: any;
  venueAddressCity: any;
  venueAddressState: any;
  venueAddressZip: any;
  loggedInUserEmail: any;
  progress$;
  showProgressBar = false;
  uploadComplete = false;
  expectedHeaderArray: any;
  spreadsheetHeaders: string;
  incorrectHeaders = false;
  constructor(
    private fns: AngularFireFunctions,
private router: Router
  ) {}

  ngOnInit(): void {

     this.venueDocId  = JSON.parse(sessionStorage.getItem('VenueDocId'));
     this.venueName = JSON.parse(sessionStorage.getItem('VenueName'));
     this.venueAddressOne = JSON.parse(sessionStorage.getItem('VenueAddressOne'));
     this.venueAddressCity = JSON.parse(sessionStorage.getItem('VenueAddressCity'));
     this.venueAddressState = JSON.parse(sessionStorage.getItem('VenueAddressState'));
     this.venueAddressZip = JSON.parse(sessionStorage.getItem('VenueAddressZip'));
     this.loggedInUserEmail = JSON.parse(sessionStorage.getItem('LoggedInUserEmail'));
  }

  fileUplodFn(e){

    const selectedFile =  e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    const headerArray = [];
    fileReader.onload = (event) => {
     // console.log('EVENT OBJ', event);

    let binaryData = event.target.result;
   // console.log('BinaryData OBJ', binaryData);

      let workbook = XLSX.read(binaryData, {type: 'binary', cellDates: true});

      var range = XLSX.utils.decode_range(workbook.Sheets.Sheet1['!ref']);
      var C, R = range.s.r; /* start in the first row */
      /* walk every column in the range */
      for(C = range.s.c; C <= range.e.c; ++C) {
          var cell = workbook.Sheets.Sheet1[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */
          console.log('CELL', cell);
          var hdr =  C; // <-- replace with your desired default
          if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);
          headerArray.push(hdr);
      }

      this.expectedHeaderArray =  this.headerNamesArray.toString();
      this.spreadsheetHeaders = headerArray.toString();
      // console.log('expectedHeaderArray', this.expectedHeaderArray);
      // console.log('ssArry', this.spreadsheetHeaders);

      const comparisonRes = this.expectedHeaderArray === this.spreadsheetHeaders;
      //  console.log('Comparison Res', comparisonRes);

        if(!comparisonRes){
          this.incorrectHeaders = true;
          this.uploadComplete = false;
          setTimeout(() => {
            this.incorrectHeaders = false;
          }, 5000);
        } else {
          this.showProgressBar = true;
           workbook.SheetNames.forEach((sheet: any) => {
        const e = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);

       e.forEach((obj: any) => {
      //  console.log('FOR EACH OBJ', e);
        setTimeout(() => {
         const startDate = obj.startDate;
         const t = startDate.getTime();
         obj.eventTimestamp = t;
         const startDay = startDate.getDate();
         const startMonth = (startDate.getMonth() + 1);
         const startYear = startDate.getFullYear();
         obj.startDate = `${startYear}-${startMonth}-${startDay}`;
         if (obj.duration === 'multi'){
          const endDate = obj.endDate;

       //   console.log('endDay', endDate);
          const endDay = endDate.getDate();
       //   console.log('endDay', endDay);
          const endMultiDate = (endDate.getDate() +1);
       //   console.log('endMultiDate', endMultiDate);
          const endMonth = (endDate.getMonth() + 1);
       //   console.log('endMonth', endMonth);
          const endYear = endDate.getFullYear();
        //  console.log('endYear', endYear);
         // obj.endDate = new Date('endYear-mm-endDay');
         obj.endDate = `${endYear}-${endMonth}-${endDay}`;
         obj.endMultiDate = `${endYear}-${endMonth}-${endMultiDate}`;
       //   obj.endDateTime = `${endYear}-${endMonth}-${endDay}T23:59:00`;
          obj.multiDay = true;
          obj.eventDuration = "Multi Day";
        } else if (obj.duration === 'single'){
          obj.endDate =  obj.startDate;

          obj.singleDay = true;
          obj.eventDuration = "Single Day";
        }
        obj.venueName = this.venueName;
        obj.eventVenue = `${this.venueName} - ${this.venueAddressOne}, ${this.venueAddressCity}, ${ this.venueAddressState} ${ this.venueAddressZip}`;
        obj.branchName = `${GlobalConstants.branchNameTitleCase}`;
        obj.branchDocId = `${GlobalConstants.globalBranchDocId}`;
        obj.branchCalendarSecretName = `${GlobalConstants.branchCalendarSecretName}`;
        obj.createdBy = this.loggedInUserEmail;
        obj.timeZoneName = `${GlobalConstants.branchCalendarTimezone}`;
        const eventId = AutoId.newId();
        // const eventId = this.afs.createId();
        obj.venueDocId = this.venueDocId;
        const d = {
          ...obj,
          eventId,
          docId: eventId,
          readId:eventId,
          updateId:eventId,
          deleteId:eventId,
          collectionName: `${GlobalConstants.rootCollectionAndBranchDoc}/branchVenues/${this.venueDocId}/branchEvents`
        };
        const masterCalId =  eventId;
      //  console.log('Master Cal Id', masterCalId);
        const corpCalendar = {
          ...obj,
          masterCalId,
          readId:obj.eventId,
          updateId:obj.eventId,
          deleteId:obj.eventId,
          masterCSCSchedule: 'cscEventSchedule',
          nationwideCalendarSecretName: `${GlobalConstants.nationwideCalendarSecretName}`,
          timeZoneName: `${GlobalConstants.corporateCalendarTimezone}`,
          eventVenue: `${this.venueName} - ${GlobalConstants.branchNameTitleCase}`,
          eventTitle: `${obj.eventTitle} - ${this.venueName} (${GlobalConstants.branchNameTitleCase})`
        }


      // console.log('CAL OBJ', d);
        const createEvent = this.fns.httpsCallable('createEvent');
        createEvent(d).subscribe(result => {
       //  console.log('CREATE EVENT RES', result);
        });
     const createMasterCalendarEvent = this.fns.httpsCallable('createEvent');
          createMasterCalendarEvent(corpCalendar).subscribe(result => {
          //  console.log('Master Calendar Event RES', result);
          });
        }, 1000);
      });

      this.progress$ = Math.round((event.loaded / event.total) * 100);

      if(event.loaded === event.total){
        setTimeout(() => {
          this.showProgressBar = false;
          this.uploadComplete = true;
          this.router.navigateByUrl('/admin/venue/detail/events',
          {state: {
            venueDocId: this.venueDocId
          }})
        }, 2500);
      }
   this.convertedJSON = JSON.stringify(e, undefined, 4);
      });
        }
    }
  }
}
