<div class="pageWrapper">
  <div class="gif" appLandingPageBackgroundHeader ></div>
  <header >
    <div class="videoSectionWrapper">
      <app-header-video></app-header-video>
      </div>
    <div class="leaderSectionWrapper">
      <app-branch-bio></app-branch-bio>
    </div>
  </header>
</div>
