import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class DateAndTimeService {

  constructor() { }

  convertTimestampFn(res: any) {
    res.forEach((x: any) => {
      // console.log(x);
      for (const [k, v] of Object.entries(x)) {
        //   console.log(`${k}: ${v}`);
        if (v instanceof firebase.firestore.Timestamp) {
          // console.log('X KEY & V VALUE',`${k}: ${v}`);
          // console.log('X KEY', x[k]);
          // console.log('V VALUE', v);
          // x[k] = `${v.toDate()}`;
          x[k] = x[k].toDate();
          // x[k] = new Date(x[k].seconds * 1000);
        } else {
          x[k] = `${v}`;
        }
        // console.log('X', x);
      }
    });
    return res;
  }

  convertDate(firebaseObject: any) {
    if (!firebaseObject) return null;
    for (const [key, value] of Object.entries(firebaseObject)) {
      // covert items inside array
      if (value && Array.isArray(value) )
        firebaseObject[key] = value.map(item => this.convertDate(item));
      // convert inner objects
      if (value && typeof value === 'object' ){
        firebaseObject[key] = this.convertDate(value);
      }
      // convert simple properties
      if (value && value.hasOwnProperty('seconds'))
        firebaseObject[key] = (value as firebase.firestore.Timestamp).toDate();
    }
    return firebaseObject;
  }

  pad(n: any) {
    return n < 10 ? '0' + n : n;
  }
  convertDateFormat(newDate: any) {

    const createDateFromTimestamp = newDate.valueOf();
   // console.log('Dep FromTimestamp', createDateFromTimestamp);
    const createDateFromFromTimestamp = new Date(createDateFromTimestamp);
  //  console.log('Dep Date from FromTimestamp', createDateFromFromTimestamp);
    const myDate = new Date(createDateFromFromTimestamp);
    const date = myDate.getDate();
    const month = myDate.getMonth();
    let year = myDate.getFullYear().toString();
    year = year.slice(-2);
    //  const year = myDate.getFullYear();
    const ddmmyyyy = this.pad(month + 1) + '/' + this.pad(date) + '/' + year;
  //  console.log('Dep Date from FromTimestamp Converted', ddmmyyyy);
    return ddmmyyyy;
  }

  createdAtOffsetTimezoneDateFn() {
    const offset = new Date().getTimezoneOffset();
 //   console.log('OFFSET', offset);
    const created = new Date();
    const adjustedDateTimezone = created.setMinutes(created.getMinutes() + offset);
  //  console.log('Adjusted Date Timezone', adjustedDateTimezone);
    const createdDate = new Date(adjustedDateTimezone);
  //  console.log('CREATED DATE', createdDate);
    return createdDate;
  }
}
