import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from 'src/app/services/read.service';

@Component({
  selector: 'app-homepage-clients',
  templateUrl: './homepage-clients.component.html',
  styleUrls: ['./homepage-clients.component.css']
})
export class HomepageClientsComponent implements OnInit {
  specialEvents: any;
  sports: any;
  venues: any;

  constructor(
    private readService: ReadService

  ) { }

  ngOnInit(): void {
    this.specialEventsFn();
    this.sportsFn();
    this.venuesFn();
  }

  specialEventsFn(){
   this.specialEvents = this.readService.returnOrderByFilterFn(
      `${GlobalConstants.rootCollectionAndBranchDoc}/clientsPastPresent`,
      'clientTitle',
      'asc',
      'clientCategory',
      'Special Events'
    )
  }
  sportsFn(){
   this.sports = this.readService.returnOrderByFilterFn(
      `${GlobalConstants.rootCollectionAndBranchDoc}/clientsPastPresent`,
      'clientTitle',
      'asc',
      'clientCategory',
      'Sports'
    )
  }
  venuesFn(){
   this.venues = this.readService.returnOrderByFilterFn(
      `${GlobalConstants.rootCollectionAndBranchDoc}/clientsPastPresent`,
      'clientTitle',
      'asc',
      'clientCategory',
      'Venue'
    )
  }
}
