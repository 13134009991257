import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { CreateService } from 'src/app/services/create.service';
import { GlobalConstants } from 'src/app/global-variables';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
@Component({
  selector: 'app-text-editor-dialog',
  templateUrl: './text-editor-dialog.component.html',
  styleUrls: ['./text-editor-dialog.component.css']
})
export class TextEditorDialogComponent implements OnInit {

  @Output() emailDocId = new EventEmitter();
  editorHeight: any;
  patchValues: any;





constructor(
  private createService: CreateService,
  private afs: AngularFirestore,
  public dialog: MatDialog,
  public dialogRef: MatDialogRef < TextEditorDialogComponent > ,
  @Inject(MAT_DIALOG_DATA) public data: any
) {}

  ngOnInit(): void {
    // console.log('PatchValues', this.data.patchValues);
    this.editorHeight = this.data?.editorHeight;
    this.patchValues = this.data?.patchValues;
  }

  textReturnedFn(e){
    this.dialogRef.close({data: e});
  }
}
