import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';

export interface FilesUploadMetadata {
  uploadProgress$: Observable < number > ;
  downloadUrl$?: Observable < string > ;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private storage: AngularFireStorage) { }

  uploadFileAndGetMetadata(
    fileObj: any,
    mediaFolderPath: string,
    metadata: any
  ): FilesUploadMetadata {
  //  console.log('fileObj', fileObj);
  //  console.log('Media Folder Path', mediaFolderPath);
  //  console.log('metadata', metadata);
    //  const { name } = fileToUpload;
    // const file = fileObj.target.files[0];
    // const file = fileObj.base64;
    // const fileName = file.name;
    const filePath = `${mediaFolderPath}`;
    try {
      const uploadTask: AngularFireUploadTask = this.storage.upload(
      filePath,
      fileObj,
      metadata
    );
    return {
      uploadProgress$: uploadTask.percentageChanges(),

      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
    } catch (error) {
    //  console.log('UPLOAD ERROR', error);
    }

  }

  private getDownloadUrl$(
    uploadTask: AngularFireUploadTask,
    path: string,
  ): Observable < string > {
    return from(uploadTask).pipe(
      switchMap((_) => this.storage.ref(path).getDownloadURL()),
    );
  }

  bytesToSize(bytes: number): string {
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return 'n/a';
  }
    const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    if (i === 0) {
      return `${bytes} ${sizes[i]}`;
  }
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
  }

  getPublicUrl(path: string) {
  //  console.log('PATH', path );
    return  this.storage.ref(path).getDownloadURL();
  }
}
