import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-video-component',
  templateUrl: './how-to-video-component.component.html',
  styleUrls: ['./how-to-video-component.component.css']
})
export class HowToVideoComponentComponent implements OnInit {
@Input() videoUrl: any;
@Input() pageTitle: any;
@Input() pageCopy: any;

  constructor() { }

  ngOnInit(): void {
  }

}
