
<div class="titleWrapper">
  <h2 >We Use Cookies</h2>
</div>
<mat-dialog-content class="mat-typography permitted-or-not-dialog">
  <div>
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-card fxFlex="90">
        <mat-card-content>

              <div class="noticeWrapper">
                <p class="bold">{{policyDate}}</p>
               <p>Cookies help us deliver the best experience on our website. By using our website, you agree to our <a (click)="goToPrivacyPageFn()">
                 Privacy Policy
               </a>  and use of cookies.</p>
              </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="closeDialog()" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
