import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from 'src/app/services/read.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  branchAddress1: any;
  branchAddress2: any;
  branchAddressCity: any;
  branchStateAbbr: any;
  branchAddressZip: any;
  branchPhoneNumber1: any;
  branchEmailAddress: any;
  branchFacebookLink: any;
  branchInstagramLink: any;

  constructor(
    private readService: ReadService
  ) { }

  ngOnInit(): void {
    this.returnBranchDataFn();
  }


  async returnBranchDataFn(){
    const d = await this.readService.returnPromiseWhereFn(
      `cscBranchDirectory`,
      'docId',
      `${GlobalConstants.globalBranchDocId}`
    );
    const [r] = [...d];
    this.branchAddress1 = r.branchAddress1;
    this.branchAddress2 = r.branchAddress2;
    this.branchAddressCity = r.branchAddressCity;
    this.branchEmailAddress = r.branchEmailAddress;
    this.branchStateAbbr = r.branchStateAbbr;
    this.branchAddressZip = r.branchAddressZip;
    this.branchPhoneNumber1 = r.branchPhoneNumber1;
    this.branchFacebookLink = r.branchFacebookLink;
    this.branchInstagramLink = r.branchInstagramLink;
  }
}
