import { Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from 'src/app/services/read.service';
@Component({
  selector: 'app-header-video',
  templateUrl: './header-video.component.html',
  styleUrls: ['./header-video.component.css']
})
export class HeaderVideoComponent implements OnInit {
  player: any;
  // player = new Player.player();
  videoId: number = 734178755;
  videoPlayer: HTMLElement;
  targets: NodeListOf<HTMLImageElement>;
  videoOptions: { id: number; responsive: boolean; };
  constructor(
    private readService: ReadService
  ) { }

  ngOnInit(): void {

    this.fetchVideoIdFn();

    // this.videoOptions = {
    //   id: 565864642,
    //   responsive: true
    // };
    // this.player = new Player('vidIframe', this.videoOptions);
    // this.player.play();
    // this.player.setMuted(true);
    // this.player.getDuration().then(function(duration) {
    //   console.log('VIMEO duration', duration);
    // });

    // this.player.on('finish', function(data) {
    //   console.log('VIMEO finish', data);
    // });
    // this.player.on('timeupdate', function(data) {
    //   this.videoPlayer = document.getElementById('vidIframe');
    //   if(data.seconds > 57){
    //     document.getElementById("homepageLeaderComponent").scrollIntoView({behavior: "smooth"});
    //   }
    // });

    // document.getElementById('vidIframe').addEventListener('ended', function (event) {
    //   console.log('VIMEO EVENT', event);
    // });

    // this.player.getEnded().then(function(ended) {
    //   console.log('VIMEO ENDED', ended);    }).catch(function(error) {
    //     console.log('VIMEO ERROR', error);
    // });
    // setTimeout(() => {
    //     this.intersectionFn();
    // }, 1000);
  }


  async fetchVideoIdFn(){
    const v = await this.readService.returnPromiseWhereFn(
      'cscBranchDirectory',
      'docId',
      `${GlobalConstants.globalBranchDocId}`
    );
    const [a] = [...v];
   // console.log('A',  a);
    this.videoOptions = {
      id: a.homepageVideoId,
      responsive: true
    };
    this.player = new Player('vidIframe', this.videoOptions);
    this.player.play();
    this.player.setMuted(true);
    this.intersectionFn();
  }

  intersectionFn(){
    this.targets = document.querySelectorAll('.videoWrapper');
   // console.log('Targets',  this.targets);
    const lazyLoad = target => {
      const io = new IntersectionObserver((entries, observer) => {
      //  console.log('Entries', entries);
      //  console.log('Observer', observer);
        entries.forEach(entry => {
         // console.log('😍');
          if (entry.isIntersecting) {
          //   console.log('intersecting', entry);
            this.player = new Player('vidIframe', this.videoOptions);
            this.player.play();
            // const img = entry.target;
            // const src = img.getAttribute('data-lazy');
            // img.setAttribute('src', src);
            // img.classList.add('fade');
           // observer.disconnect();
          } else {
            this.player.pause();
          }
        });
      });
      io.observe(target);
    };
    this.targets.forEach(lazyLoad);
  }
}
