import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { RsaService } from 'src/app/services/rsa.service';
import { MemberService } from '../member-profile/member.service';
import * as forge from 'node-forge';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-wish-api-test',
  templateUrl: './wish-api-test.component.html',
  styleUrls: ['./wish-api-test.component.css']
})
export class WishApiTestComponent implements OnInit {
  privateKey = `-----BEGIN RSA PRIVATE KEY-----
  MIIJKQIBAAKCAgEAyyCWpGSJcOqTdWA/C5DoFujnUxhaaP1GcK3zbdm+KHOA+h9A
UuubEq0dQHdus4Itot/nLoAcw3X2Kg0mbqRoxXr7DdzGbyL2k2I7MWQZI7gkSoeh
1C9GuQgww2PK6C5xnQCJJJStN6RLu6og7jc1HZAcV1hFzHRkA1SQbUbSvh6v5ItB
YtkZxLdsp3M3WEqnf92mjQVyR/Ifm2QAWGQLlPQSWu0Kyk1Z/feWH4FY0MZPXKmB
9XT/6ivq5SaKFRZr8v+9lNCPyvGcu8XcrP0vdZCAF85xJJuBzzfMgZM7jo2YFxoq
Upd6ynGgSXBZIAWxpsSlbUNn5FKHW5+XI7FS8df3y3WPmwWQj+CGLAYXuHVRMPpE
q7HAwWcuxmsw6DGa4uK+i/V+UU0PqjD/u80MV4bRb12rDPaW1hYGWqrXPR+YWpBB
tA6homh+GYgfMuNbe63p8mZpL7S/7oB8lNwwjGAUgkikF4+io1C0KfhEGchCEGDK
DZ3yNby5aOobtGLY0NbfNCBc9CwjZ6vhok9z8dA55W2rpEn2IEVhYDD5lf3KwWir
IicjyzwRpJAO+o4Nck6CmlTbwC8WneQnqJMOkqKgjexhU48YPBLKiKpJAySW7JlR
u3IUZtfyhB0E+uX7JjPglj+Xm6a6nN+ckx05mEeihvn0PhAk9XBs+1vQGCsCAwEA
AQKCAgB7pHaN0nSmuuLPS6qUi7v6VgKY3fYKekDqTbgjYasSTFxx45vv3WIjWCS6
HPjRY6gLwGakM29OuvsAVcjTCFiN/HHc2kofVBTrWZvWUKvMxkWw6CZlVfGip42n
IZNdCQg3HSBdl/JoMCW5MbGhzxER0aqusBisfxxxuoO/QKD/AQboAqvmU2gQCeal
dsh1gdu7RVwf8vTBPmxm1dQv+ZXjMT4LAsbSpYrlwFMtEjrRuiSMrtFga7tq+xic
K31ZoLBM1ca8Usd0LOXOln3g/qxoz3BpDUxpkA7P55MgPFLATwgI5/BjHbmsiMWc
bjqR92VPsdtQSvtuS54A83+4RjPJ1JqW2npL3xQiUP1JYJPdgkcvLW2Hb8fVJ66T
7QwVm0oFZmMrLhPTANXmyVIDZzgSCChl2TM769upiO9qz61S9IRxmdrnwfJpHhOH
prVi1Z/Bb7+zypJrrv0qMylyGTgSMIiaLkmDo3BRlAakjJjYelPNOv6OHfDJyYb0
lUPJzVDC26VQZ3683ExjHPcxONTbjiApqkNZ9JpEsNuNcshdsfHKgS4OPLwLf+1e
2J7XhvCpapUbtQjoxLoMRNJV8j4Co4rTB35aC4LS/yNdRKcI70j1mFTU4fbtx82v
vafjOJFZsvgm8hamZCpkDEMygKMBK4lLkXP2OuWgAVY2pgW1wQKCAQEA55cmYXEB
AXVpCuUQcQ8MeMcHjAYZ7J7ZVjIL0B5Ah/xShsdSfSN2AWyUo88mPExOy/EfD0rz
1F2MQujwAbu/RHbqGYYfJS4UhiS4aG1ZMINBm2Mbj7Y5E69SWFnuC5jJr/v45n4v
IFPbqJbCdc1GvvgDx7Jw6xk9/gd+U3kViNZkuIDDL4KMoYoZxRXKsM75BuGtXxSc
9YEGzXPz4jMV9BiDCjNhbXsQBoencHQl/OvYoQ05WOzqzvt9XgAMjDF8MA9Ro2m2
4xAEOeR7x5c9VxpV4ATQKM1w5W3QrBq+h65yD4mfyuy+ixACOXfQ/lEk4bbqr/Wr
tWaHjXsk2ZovWQKCAQEA4Ilv1WbsM0lFPtMfPaxaAri3dHo+uLABe2n0UBEsvH0c
xInwsI0pWgGMtqP9IhfXPPPi8dShL9TIutnvBHAbP5QXK9OSd8YWSan4TTm1R7xU
kPdm7nr201Hnsw9XGDI9/9UclA0ZWriiM/W3RN/hxpk96p6Ma81KOy+1RZhIIjNl
9JQDQC1DQE3J+nG0Crl6p2/wUkX9Pf7OmMHFPKQmy+nduw2qUt1TgVxOJhAJEyu2
M3Y2E7LiU/JBT53Mgy4r3xZ35mNjNk6c659AdH6AOVZCQ3Z4S6PVe9pCeYQ++9QY
QAz4s0O/PJHHz1kA1PiCFyAcJ+iZzk7aEEnBYZu3IwKCAQBb139rCSuC7pL3tRuu
Gm+xvgxqJUlGo0BBhJtWr4ib5R69QHKgGd4EdpED3XSmWQSusDLHF8xWnbqwFjyI
E3Korhfsg1n6nqmZARr4ntTN/l+YRdd6SKOeANtBGnjitHbXia+NiOEbiuYDl7YK
jZvOxACLw2IwedQx17rrU4uMtqk+2iGNx9IJhgDuPdQiBhKP/hOJL12HpuGRvLgt
LmE4ajgdZX7vsewLCuUGHamrAcTpKZtifqIn72gqTQ6s0jsTvB9+FMBoj8N9SfUk
k//JeTIxIveCFub8tW4Zsol4uV+Wddee3AA8md6n97I0CO39bVLo8R5lVAHRw9I7
UYARAoIBAQDfi/WroSzIYspuIgOYg1+aFg2QCaUoexL+rB5xGKhRgL6DblITdHcX
BTPzIpGCv/mq1aW83tUbqd2vKgqtgG+gfPAU0i+gz7ag4Hg1ap7xLK+FJMt14zaa
bZEg1MUBcgQIEUTCocf8apYS5S/xgnRmJWtfizqCmYCuLHFtOI+YfVOkjTgvTOEH
xGqyzUFS7BcRXIEHaGcYsfc+XqzgEw4NNmlrMc/BcFkTEbM8cfqW+44K7kYolpB+
w1IC0jgq4m0LTnNRDOJZzyqr64FgehFvBFI0mF5r8FE7XbGRCfkq/56lJkT1NR3Q
T9N+vD33yvK501403X9Dx32TedruMvnBAoIBAQDCL1vntnjFDWTZ06C3JiBzmaho
ZdDrjWL+ZYl9TmCFELh1A5L6jCccCsoNYz6OhjPX+GUnv5yBT1VARK+oN9BIKiL2
3iClZqgN1Vmy/O2dPfZwEjF4jqeZ/Srbe8Uwl0uzgheAD4st79VCiOBPsOuQ9fNP
gU2G03PRioOXX81nPiQJzTdK4JR/JvhXSrE4ExPsePjBDkQpmbEgvTD7oArNqONt
n/+fQxcQH+p5kasSN8FhxSygrXCzxN1NtzjnmN8Lo6H0Ujzpc7Aa7HMybCsDy/h7
3ovV+0VR74v1zScYRKcG2kbW4JBaXi5dK6SdjZADnuLf6p7boPoZaXhEc9cq
  -----END RSA PRIVATE KEY-----`;

  formCSSClassName = 'verifyUserForm';
  formConfig: any;
  empno: string;
  outboundIP: any;
  resStatus: any;
  resMessage: any;
  resData: any;
  constructor(
    private  memberService: MemberService,
    private rsaService: RsaService,
    private fns: AngularFireFunctions,
    private http: HttpClient

  ) { }

  ngOnInit(): void {
    this.formConfig = this.memberService.verifyEmployeeFn();
    // this.testIp();
  }
  submittedFormData(d: any){
    // Employee# 411022
    // LastName: Bamrungpong
   // console.log('TEST & PROD', d.testAndProdUrls);
  //  const url = 'http://esssrv12.schedulingsite.com/api/User/GetEmployeeContact';
   const url = 'https://essapiv2.schedulingsite.com/api/User/GetEmployeeContact'
      const lastName = d.personLegalNameLast.trim().toLowerCase();
     const trimEmployeeNumber = d.personEmployeeNumber.trim();
    const wishAPI = {
    url: d.testAndProdUrls,
     lastName,
     employeeNumber: trimEmployeeNumber,
      publicPem: 'csc-employee-api-public-pem',
      privatePem: 'csc-employee-api-private-pem'
  }

  // console.log('Outbound Data to Cloud Function:', wishAPI)
  const callPostAPI = this.fns.httpsCallable('fetchEmpDataUsingStaticIp');
  callPostAPI(wishAPI).subscribe((result) => {
   // console.log('WISH RESULT:', result);
    this.resStatus = result.DataResponse.status;
    this.resMessage = result.DataResponse.message;
    this.resData = JSON.parse(result.DataResponse.data);
   // console.log('ResData:', this.resData);
      });
  }

  testIp(){
    const encryptLastName = this.rsaService.encryptWithPublicKey("Bamrungpong");
    const encryptEmpNo = this.rsaService.encryptWithPublicKey("411022");
    const testUrl = 'https://essapiv2.schedulingsite.com/api/User/GetEmployeeContact';
    this.http
    .post(testUrl, {
      EmployeeNo: encryptEmpNo,
      LastName: encryptLastName
    }).subscribe( res => {
      var privateKey = forge.pki.privateKeyFromPem(this.privateKey);
      var ctBytes = forge.util.decode64(res[0].data);
      var plaintextBytes = privateKey.decrypt(ctBytes);
       res[0].data = plaintextBytes.toString();
      this.empno = JSON.stringify(res[0]);
     // console.log('EMP NO', this.empno)
    })
    }
}
