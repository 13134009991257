<h2 mat-dialog-title></h2>
<mat-dialog-content [class]="class">
<div class="confirmationSection">
    <p>Confirm your availability for the following {{eventDuration}} event:
  </p>

<h3>{{eventTitle}}</h3>
<p>{{eventVenue}}</p>
<p>{{startDate}}</p>
</div>

<app-dynamic-form
(submittedFormData)="receivedDynamicDataFn($event)"
[f]="formConfig"
[class]="class">
<span class="btnLabel">Submit</span>
</app-dynamic-form>


<!-- <div class="confirmationBtn">
  <button mat-raised-button class="greenBtn" (click)="confirmFn()">Confirm</button>
</div> -->
</mat-dialog-content>
