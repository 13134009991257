import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CreateService } from 'src/app/services/create.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import firebase from 'firebase/app';
import { SnackbarService } from 'src/app/services/snackbar.service';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('collectionPath') collectionPath: any;
  @Input('publicOrPrivate') publicOrPrivate: any;
  @Input('documentId') documentId: any;
form: FormGroup;
  selectedFile: any;
  isFileSize: string;
  fileToLarge: boolean;
  showFileMeta: boolean;
  showProgressBar: boolean;
  showUploadButton = false;
  selectedFileName: any;
  fileToUpload: any;
  selectedFileSize: any;
  siteService: any;
  collectionName;
  uploadProgress$: Observable < number > ;
  permittedAccess: any;
  getDownloadUrl$: Observable < number > ;
  progress$: any;
  uploadDocId: string;
  showSubmitBtn = true;
  showUploadMsg = false;
  uploadMsg;
  downLoadUrlComplete: string;
  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private fileService: FileUploadService,
    private snackbarService: SnackbarService,
    private createService: CreateService
  ) { }

  ngOnInit(): void {

  //  console.log('COL DATA',  this.collectionPath);

    this.collectionName = 'corpBranchTest';
    this.permittedAccess = 'public';
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      itemName: [''],
      itemUploadedDate: [''],
      itemDescription: [''],
      imgUrl: ['', [Validators.required]]
    });
  }

  selectedFileFn(event: any) {
    this.selectedFile = event.target.files[0];
    this.isFileSize = (this.selectedFile.size / 1048576).toFixed(1) + 'MB';
    if (this.selectedFile.size > 10485760) {
      this.fileToLarge = true;
      this.showFileMeta = false;
      this.showProgressBar = false;

      this.showUploadButton = false;
    } else {
      this.fileToLarge = false;
      this.showFileMeta = true;
      this.showProgressBar = true;
      this.selectedFileName = this.selectedFile.name;
      this.fileToUpload = this.selectedFile;
      this.selectedFileSize = this.fileService.bytesToSize(this.selectedFile.size);
      this.showUploadButton = true;
    //  console.log('CSC File To Upload ', this.fileToUpload);
    }
  }
  submitFormData() {
    const d = this.form.value;
    const collectionPath = this.collectionPath;
    //  d.itemName = str.toLowerCase();
    this.uploadDocId = this.afs.createId();
    const origFileName = this.fileToUpload.name;
    const publicOrPrivate = this.publicOrPrivate;
    const metadata = {
      customMetadata: {
        fileStatus: publicOrPrivate,
        firestoreRecordPath: `${collectionPath}/${this.documentId
}`,
        originalFileName: origFileName
      }
    };
    const storagePath = `${collectionPath}/${this.documentId
}`;
    d.itemId = this.documentId
;
    // d.documentUrl = this.contractDocumentUrl;
    const formData = {
      ...d,
      fileId: this.documentId,
      storagePath

    };
  // console.log('CSC storagePath', storagePath);
    try {
          const {
            uploadProgress$, downloadUrl$
          } =  this.fileService.uploadFileAndGetMetadata(this.fileToUpload, storagePath, metadata);

          uploadProgress$.subscribe(d => {
         //   console.log('PROG', d);
            this.progress$ =  d;
          })

         //   console.log('CSC Upload Progress', this.uploadProgress$);
         //   console.log('getDownloadUrl Progress', downloadUrl$);
            downloadUrl$.subscribe(d => {
              this.downLoadUrlComplete = d;
              const updatedData = {
                ...formData,
                itemUploadedDate: firebase.firestore.FieldValue.serverTimestamp(),
                documentUrl: d
              }

              try {
                this.createService.createItRecordFn(
                collectionPath, this.documentId, updatedData)

            //  console.log('DOWN URL', d);
           //   console.log('Updated Data', updatedData);

              if(this.downLoadUrlComplete){
            //    console.log('PROG IS 100')
               this.showFileMeta = false;
             this.showProgressBar = false;
             this.showUploadButton = false;
              }
                     setTimeout(() => {
                       const snackClass = ['snackSuccess'];
                       const message = 'Your file has been uploaded.';
                       this.snackbarService.openSnackBar(message, snackClass);
                     }, 1500);
              } catch (error) {
           //   console.log('NO URL', error);

                this.uploadMsg = 'Your file has NOT been uploaded, please try again.'
 const snackClass = ['snackError'];
            const message = '!Your file has NOT been uploaded, please try again.';
            this.snackbarService.openSnackBar(message, snackClass);
              }
            });
          } catch (error) {
         //   console.log('Outer try catch NO URL', error);
            // const snackClass = ['snackError'];
            // const message = '! Record NOT Added';
            // this.snackbarService.openSnackBar(message, snackClass);
          }
        }
}
