<!-- <h2>File Upload</h2> -->
<div>
  <p>The Excel spreadsheet headers <span class="bold">must</span> be in the same format as the image below.</p>

  <ng-container *ngFor="let x of spreadsheetExample | async">
    <img
      class="imageWrapper"
      [src]="x.imageUrl"
      alt="Excel Spreadsheet Example"
    />
  </ng-container>
</div>

<!-- Upload  -->
<ng-container *ngIf="!incorrectHeaders">
<form id="file-upload-form" class="uploader">
  <input id="file-upload" type="file" name="fileUpload"
  (change)="fileUplodFn($event)"
  accept=".xls, .xlsx" />

  <label for="file-upload" id="file-drag">
    <img id="file-image" src="#" alt="Preview" class="hidden">
    <div id="start">

      <!-- <div>Select file</div> -->
      <!-- <div id="notimage" class="hidden">Please select an image</div> -->
      <span id="file-upload-btn" class="btn btn-primary">Select file</span>
    </div>

    <div *ngIf="showProgressBar">
      <!-- <div class="progressBar" style="--width: 10" data-label="Progress..."
        mode="determinate">
    </div>Progress:
      {{progress$ ? progress$ : 0}}% -->
      <div class="progressBarWrapper">
        <mat-progress-bar
          class="progressBar"
          data-label="Progress..."
          [value]="progress$"
          mode="determinate"
        >
        </mat-progress-bar
        >

        <ng-container *ngIf="uploadComplete">
          <h4>Upload complete, redirecting...</h4>
        </ng-container>
        <ng-container *ngIf="!uploadComplete && !incorrectHeaders">
          Progress: {{ progress$ ? progress$ : 0 }}%
        </ng-container>
      </div>
    </div>
  </label>
</form>
</ng-container>


   <ng-container *ngIf="incorrectHeaders">
      <div class="errorMsg">
        <h4>THE HEADERS ARE INCORRECTLY FORMATTED</h4>
        <div class="expectedFormat">
          <p class="sectionHeading">Expected:</p>
          <p class="sectionContent">{{expectedHeaderArray}}</p>
        </div>
        <div class="spreadsheetFormat">
          <p class="sectionHeading">Spreadsheet:</p>
          <p class="sectionContent">{{spreadsheetHeaders}}</p>
        </div>
      </div>
    </ng-container>
