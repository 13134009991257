import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from 'src/app/services/read.service';

@Component({
  selector: 'app-homepage-venues',
  templateUrl: './homepage-venues.component.html',
  styleUrls: ['./homepage-venues.component.css']
})
export class HomepageVenuesComponent implements OnInit {
  venueData: any;

  constructor(
    private router: Router,
    private readService: ReadService
  ) { }

  ngOnInit(): void {
    // this.venueData = this.readService.returnAllRecordsFn(`${GlobalConstants.rootCollectionAndBranchDoc}/branchVenues`, 'venueName', 'asc');

    this.fetchVenueDataFn();
  }

showVenueFn(docId: string, venueName: string){
this.router.navigateByUrl('/venues/i', {
  state: {
    venueDocId: docId,
    venueName
  }
})
}

async fetchVenueDataFn(){
  this.venueData = this.readService.returnOrderByFilterFn(
    `${GlobalConstants.rootCollectionAndBranchDoc}/branchVenues`,
    'venueName',
    'asc',
    'published',
    'online');
}
}
