import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.css']
})
export class WysiwygComponent implements OnInit {
  editorContent: any;
  content: any;


  constructor() { }

  ngOnInit(): void {

    this.wysiwygFn();
}

wysiwygFn(){
  let btn = document.querySelector(".sai");
  let getText = document.querySelector(".getText");
  this.content = document.querySelector(".getcontent");
  this.editorContent = document.querySelector(".editor");

  btn.addEventListener("click", () => {
    let s = this.editorContent.innerHTML;
    this.content.style.display = "block";
    this.content.textContent = s;
  });

  getText.addEventListener("click", () => {
    const old = this.editorContent.textContent;
    this.content.style.display = "block";
    this.content.textContent = old;
  });

}

getHtml(){
  let s = this.editorContent.innerHTML;
  this.content.style.display = "block";
  this.content.textContent = s;
 // console.log( s)
}
   link() {
    let url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
  }

   copy() {
    document.execCommand("copy", false, "");
  }

   changeColor() {
    let color = prompt("Enter your color in hex ex:#f1f233");
    document.execCommand("foreColor", false, color);
  }


  //  getImage() {
  //   let file = document.querySelector("input[type=file]").files[0];

  //   let reader = new FileReader();

  //   let dataURI;

  //   reader.addEventListener(
  //     "load",
  //     () => {
  //       dataURI = reader.result;

  //       const img = document.createElement("img");
  //       img.src = dataURI;
  //       this.editorContent.appendChild(img);
  //     },
  //     false
  //   );

  //   if (file) {
  //     console.log("s");
  //     reader.readAsDataURL(file);
  //   }
  // }

   printMe() {
    if (confirm("Check your Content before print")) {
      const body = document.body;
      let s = body.innerHTML;
      body.textContent = this.editorContent.innerHTML;

     // document.execCommandShowHelp;
      body.style.whiteSpace = "pre";
      window.print();
      location.reload();
    }
  }

}
