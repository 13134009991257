export const sectionMetadata = {
  homePage: {
    meta: {
      title: 'CSC Las Vegas - The Leader in Crowd Management',
      description: 'Recognized worldwide pioneer, Homeland Security SAFETY Act Certified & only employee owned company in the crowd management field through over five decades of continual refinement and evolution.'
    }
  },
  bulletinBoardPage: {
    meta: {
      title: 'CSC Las Vegas - Bulletin Board',
      description: 'Lastest news from our world famous venues & events, and the event staff that kep you safe.'
    }
  },
  eventsPage: {
    meta: {
      title: 'CSC Las Vegas - Events',
      description: `Events from some of the world's most famous venues.`
    }
  },
  venuesPage: {
    meta: {
      title: 'CSC Las Vegas - Venues',
      description: `Some of the world's most famous venues.`
    }
  },
  faqPage: {
    meta: {
      title: 'CSC Las Vegas - FAQs',
      description: `Answers to your questions about CSC Las Vegas.`
    }
  },
  newsfeedPage: {
    meta: {
      title: 'CSC Las Vegas - Newsfeed',
      description: 'Lastest news from our world famous venues & events, and the event staff that kep you safe.'
    }
  },
  teamPage: {
    meta: {
      title: 'CSC Las Vegas - Our Staff',
      description: 'Be part of the experience, apply today!'
    }
  },
  joinUsPage: {
    meta: {
      title: 'CSC Las Vegas - Join Us!',
      description: 'From a multitude of backgrounds with decades of experience you can rest assured you are in safe hands.'
    }
  },
  privacyPolicyPage: {
    meta: {
      title: 'CSC - Privacy Policy',
      description: 'The Policy describes the types of information we obtain about visitors, how we use the information, and with whom we share it.'
    }
  },
  safetyActPage: {
    meta: {
      title: 'CSC Las Vegas - Homeland Security SAFETY Act Certified',
      description: 'The first to obtain SAFETY Act Designation & Certification for event security services & crowd management by the U.S. Department of Homeland Security. CSC remains the only company in these industries to achieve these awards.'
    }
  },
  locationsPage: {
    meta: {
      title: 'CSC Las Vegas - Branch Locations',
      description: 'More than forty eight branches nationwide, serving 200 plus cities, keeping millions of patrons safe at 25,000 plus events each year!'
    }
  },
  esopPage: {
    meta: {
      title: 'CSC Las Vegas - Employee Stock Ownership Program',
      description: 'As the company grows, the value of your shares grow. As owners of the company, it will continue to be in our best interest to do everything we can to ensure positive growth, which will increase the value of our shares!'
    }
  },
  experienceOverviewPage: {
    meta: {
      title: 'CSC Las Vegas - Experience Overview',
      description: 'Provides crowd management services for over 110 universities & scholastic institutions, 190 stadiums, arenas & theaters, 48 convention centers, 10 NFL, 6 MLB, 4 NBA, 7 MLS & 6 NHL teams, 31 Super Bowls, 8 World Series, 4 MLB All-Star Games, 9 NCAA Men’s Final Four, 5 annual College Bowl Games, 7 NBA Finals, 10 Olympic Games, 5 Presidential Inaugurations, 4 Papal Visits, and 2 FIFA World Cups.'
    }
  }
};
