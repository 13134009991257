import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private afs: AngularFirestore) { }

  customPatchValue(data: any, formGroup: FormGroup) {
    //  console.log(data);
    //  console.log(formGroup);
    data.map(k => {
      const truthy = Object.keys(k).filter(v => k[v] !== undefined || k[v] !== null || k[v] !== '');
      const newObj = {};
      truthy.forEach(v => Object.assign(newObj, { [v]: k[v]}));
   //   console.log(truthy);
      formGroup.patchValue(newObj);
     });

    return formGroup;
  }

   updateRecordFn(collection: string, docId: string, data: any) {
    // console.log('Collection', collection);
    // console.log('DOCID', docId);
    // console.log('DATA', data);
    try {
      this.afs.doc(`${collection}/${docId}`)
      .set(data, {
        merge: true
      });
    } catch (error) {
    //  console.log(error);
      return error;
    }
  }
   updateCollectionGroupRecordFn(path: string, data: any) {
    // console.log('Collection', collection);
    // console.log('DOCID', docId);
    // console.log('DATA', data);
    try {
      this.afs.doc(`${path}`)
      .set(data, {
        merge: true
      });
    } catch (error) {
    //  console.log(error);
      return error;
    }
  }
}


