export class GlobalConstants {
  public static branchCalendarTimezone = "America/Los_Angeles";
  public static branchCalendarSecretName = 'las-vegas-calendar-id';
  public static branchDocId: string = 'las-vegas';
  public static branchNameTitleCase: string = 'Las Vegas';
  public static branchNameLowerCase: string = 'las vegas';
  public static branchRegion: string = 'three';
  public static corporateMiscDocuments = "cscBranchDirectory/corporate/miscDocuments";
  public static corporateMiscImages = "cscBranchDirectory/corporate/miscImages";
  public static corporatePasswordlessLinks = "cscBranchDirectory/corporate/passwordlessLinks";
  public static corporateCalendarTimezone = "America/Los_Angeles";
  public static fullUrlIncSubDomain = "https://lasvegas.csc-usa.com";
  public static globalBranchDocId: string = 'las-vegas';
  public static rootCollectionAndBranchDoc = `cscBranchDirectory/las-vegas`;
  public static rootEmployeeCollection = 'cscEmployeeDirectory';
  public static nationwideCalendarSecretName = "nationwide-calendar-id";
  public static twilioPhoneNumber = "twilio-las-vegas-phone-number";
  public static twilioFlexPhoneNumber = "twilio-las-vegas-flex-phone-number";
  public static twilioAuthToken = "twilio-las-vegas-flex-auth-token";
  public static twilioSid = "twilio-las-vegas-flex-account-sid";
  public static websiteDocId: string = 'las-vegas';

}

