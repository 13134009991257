<div class="sectionWrapper">
  <div class="leaderSection">
    <div class="headerTitle">
      <h1 class="leaderH1">
        <span class="cscGoldText">CSC</span> {{ branchTitle }}
      </h1>
    </div>
    <ng-container *ngFor="let x of headerLogos | async">
 <div class="safetyActCertifiedImg">
      <img
        class=""
        [src]="x.safetyActCertifiedLogo"
        alt="DHS Safety Act Certification"
      />
    </div>
    <div class="safetyActDesignatedImg">
      <img
        class=""
        [src]="x.esopLogo"
        alt="An employee owned company"
      />
    </div>
      </ng-container>
    <div class="cscIntro">
      <ng-container *ngFor="let x of headerCopy | async">
        <p>
          {{ x.branchBio }}
        </p>
      </ng-container>
    </div>
    <!-- <div class="learnMoreBtn">
    <button mat-raised-button class="cscGoldBtn">Learn More</button>
  </div> -->
  </div>
</div>
