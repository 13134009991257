import { Injectable } from '@angular/core';
import * as Forge from 'node-forge';
@Injectable({
  providedIn: 'root'
})
export class RsaService {
publicKey = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDZo9qPUhpQsohTv7/tJo7e+Ks3
  ufecjO/uOoe6i6TGLYdRkRxeOOeewhwPRUOwJ3mKDUMhQo/PZEqbHWVFVnd3Xc2e
  DSQsCDpNHL+y5Qetcngz/0HYI2u7STZDml3jSEUwU4Fkgy/AA/TlSqAeqcp9HHsH
  n+jYohcdStxnk7fyPwIDAQAB
  -----END PUBLIC KEY-----`;

  constructor() { }

  encryptWithPublicKey(valueToEncrypt: string): string {
    const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    return window.btoa(rsa.encrypt(valueToEncrypt));
  }

}
