<div class="wrapper">
  <form [formGroup]="form">
    <div class="docSelectButton">
      <input
        type="file"
        name="file"
        id="file"
        formControlName="imgUrl"
        (change)="selectedFileFn($event)"
        class="inputFile"
        accept="application/pdf"
      />
      <label for="file"><ng-content select=".btnLabel"></ng-content></label>
    </div>
    <div *ngIf="showFileMeta">
      <div>Name: {{ selectedFileName }}</div>
      <div>Size: {{ selectedFileSize }}</div>
    </div>
    <div *ngIf="showProgressBar">
      <!-- <div class="progressBar" style="--width: 10" data-label="Progress..."
        mode="determinate">
    </div>Progress:
      {{progress$ ? progress$ : 0}}% -->
      <mat-progress-bar
        class="progressBar"
        data-label="Progress..."
        [value]="progress$"
        mode="determinate"
      >
      </mat-progress-bar
      >Progress: {{ progress$ ? progress$ : 0 }}%
    </div>

    <ng-container *ngIf="showUploadMsg">
      SUCCESS
      <h4>{{ uploadMsg }}</h4>
    </ng-container>

    <ng-container *ngIf="fileToLarge">
      <div class="errorMessage">
        The size of your image is {{ isFileSize }}, the limit is 10MB
      </div>
    </ng-container>
    <ng-container *ngIf="showUploadButton">
      <div class="subSectionWrapper">
        <button
          mat-raised-button
          class="greenBtn submitBtn"
          [disabled]="form.invalid"
          type="submit"
          (click)="submitFormData()"
        >
          <span class="material-icons"> cloud_upload </span> Upload
        </button>
      </div>
    </ng-container>
  </form>
</div>
