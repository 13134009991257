import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from 'src/app/services/read.service';
@Component({
  selector: 'app-branch-bio',
  templateUrl: './branch-bio.component.html',
  styleUrls: ['./branch-bio.component.css'],
})
export class BranchBioComponent implements OnInit {
  headerCopy: any;
  branchTitle = `${GlobalConstants.branchNameTitleCase}`;
  headerLogos: any;
  constructor(private readService: ReadService) {}
  ngOnInit(): void {
    this.fetchHeaderCopyFn();
    this.fetchHeaderLogosFn();
  }
  fetchHeaderCopyFn() {
    this.headerCopy = this.readService.returnRecordsWhereFn(
      'cscBranchDirectory',
      'docId',
      `${GlobalConstants.globalBranchDocId}`
    );
  }
  fetchHeaderLogosFn() {
    this.headerLogos = this.readService.returnRecordsWhereFn(
      'cscBranchDirectory',
      'docId',
      `corporate`
    );
  }
}
