import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from './material.module';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { AddDocumentDialogComponent } from './add-document-dialog/add-document-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImageCropperComponent } from './image-cropper/component/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EditDocumentDialogComponent } from './edit-document-dialog/edit-document-dialog.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { ConfirmRequestDialogComponent } from '../confirm-request-dialog/confirm-request-dialog.component';
import { QuillModule } from 'ngx-quill';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { PrivacyNotificationDialogComponent } from './privacy-notification-dialog/privacy-notification-dialog.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';
import { LandingPageBackgroundHeaderDirective } from './directives/landing-page-background-header.directive';
import { ExcelToJsonComponent } from './excel-to-json/excel-to-json.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { BackButtonWithStateComponent } from './back-button-with-state/back-button-with-state.component';
import { TextEditorDialogComponent } from './text-editor-dialog/text-editor-dialog.component';
import { HowToVideoComponentComponent } from './how-to-video-component/how-to-video-component.component';
import { FooterComponent } from '../public/footer/footer.component';
import { sectionMetadata } from '../public/sections-metadata';
const components = [
  PhoneFormatPipe,
  SanitizePipe,
  LoadingSpinnerComponent,
  DeleteDialogComponent,
  AddDocumentDialogComponent,
  DynamicFormComponent,
  FileUploadComponent,
  ImageCropperComponent,
  EditDocumentDialogComponent,
  ImageUploadComponent,
  DynamicTableComponent,
  ConfirmRequestDialogComponent,
  QuillEditorComponent,
  WysiwygComponent,
  PrivacyNotificationDialogComponent,
  LandingPageBackgroundHeaderDirective,
  ExcelToJsonComponent,
  BackButtonWithStateComponent,
  TextEditorDialogComponent,
  InfoDialogComponent,
  HowToVideoComponentComponent,
  FooterComponent
];

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  ImageCropperModule,
  QuillModule
];

const routes: Routes = [
  {
    path: 'esop',
    data: sectionMetadata.esopPage,
    loadChildren: () =>
      import('../public/esop/esop.module').then((m) => m.EsopModule),
  }
]
@NgModule({
  declarations: [
    ...components
    ],
  imports: [
    ...modules,
    RouterModule.forChild(routes)
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class SharedModule { }
