<div class="footerSection">
  <div class="footerContentWrapper">
    <div class="cscLogoSection">
      <div class="cscImage">
        <img
          loading="lazy"
          src="../../../assets/img/CSC-Footer-logo.png"
          alt=""
        />
      </div>
    </div>
    <div class="addressSection">
      <div class="contactinfoSection">
        <div class="phoneIcon">
          <span class="material-icons"> phone </span>
        </div>
        <div class="phoneNumber">
          <p>{{ branchPhoneNumber1 | phoneFormat: "US" }}</p>
        </div>
        <div class="emailIcon">
          <span class="material-icons"> mail_outline </span>
        </div>
        <div class="emailAddress">
          <p>
            <a class="branchEmailLink" href="mailto:{{ branchEmailAddress }}">{{
              branchEmailAddress
            }}@csc-usa.com</a>
          </p>
        </div>
        <div class="addressIcon">
          <span class="material-icons"> place </span>
        </div>
        <div class="addressInfo">
          <p>{{ branchAddress1 }}</p>
          <ng-container *ngIf="branchAddress2">
            <p>{{ branchAddress2 }}</p>
          </ng-container>
          <p>{{ branchAddressCity }}</p>
          <p>{{ branchStateAbbr | uppercase }} {{ branchAddressZip }}</p>
        </div>
      </div>
    </div>
    <div class="linksSection">
      <mat-list-item [routerLink]="['/home']"><p>Home</p></mat-list-item>
      <mat-list-item [routerLink]="['/esop']"><p>ESOP</p></mat-list-item>
      <mat-list-item>
        <a
          class="careers"
          href="https://www.teamworkonline.com/arenas-facilities-jobs/arenanetwork/losangeles-csc-jobs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Careers</p>
        </a></mat-list-item
      >
      <!-- <mat-list-item [routerLink]="['/locations']"><p>Apply</p></mat-list-item> -->
      <mat-list-item [routerLink]="['/privacy-policy']"
        ><p>Privacy</p></mat-list-item
      >
      <!-- <mat-list-item><p></p>Get Started</mat-list-item> -->
    </div>

    <!-- <div class="newsletterSection">
      <div *ngIf="!isSubmitted && !successfulRegistration">
        <button
          mat-raised-button
          class="cscGoldBtn"
          (click)="newsletterSignUp()"
        >
          Newsletter Sign Up
        </button>
      </div>
      <ng-container *ngIf="isSubmitted">
        <div>
          <p class="registering">Registering</p>
          <app-loading-spinner></app-loading-spinner>
        </div>
      </ng-container>

      <ng-container *ngIf="successfulRegistration">
        <div class="successMsg">
          {{ successMessage }}
        </div>
      </ng-container>
    </div> -->

    <div class="socialMediaSection">
      <p>Follow Us</p>
      <div class="socialIcons">
        <a
          aria-label="facebook"
          href="https://www.facebook.com/{{ branchFacebookLink }}"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fab fa-facebook"> </i>
        </a>

        <!--Instagram-->
        <a
          aria-label="instagram"
          href="{{ branchInstagramLink }}"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fab fa-instagram-square"> </i
        ></a>
        <!--LinkedIn-->
        <a
          aria-label="linkedin"
          href="https://www.linkedin.com/company/856712/"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fab fa-linkedin"> </i
        ></a>
      </div>
    </div>
  </div>
</div>
