import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AuthService } from '../auth.service';
import firebase from 'firebase/app';
import { GlobalConstants } from 'src/app/global-variables';
import { ReadService } from 'src/app/services/read.service';
import { CreateService } from 'src/app/services/create.service';

@Component({
  selector: 'app-passwordless',
  templateUrl: './passwordless.component.html',
  styleUrls: ['./passwordless.component.css']
})
export class PasswordlessComponent implements OnInit {
  email: string;
  errorMessage: string;
  isAlreadyRegistered: boolean;
  isVerifiedEmployee: boolean;
  personLegalNameFirst: any;


  constructor(
              private authService: AuthService,
              private fb: FormBuilder,
              private snackbarService: SnackbarService,
              private readService: ReadService,
              private createService: CreateService,
              private router: Router,
              private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    const url = this.router.url;
    this.confirmSignIn(url);
  }

  async confirmSignIn(url) {
    try {
      if (this.afAuth.isSignInWithEmailLink(url)) {
        let userEmail = window.localStorage.getItem('emailForSignIn');
        // If missing email, prompt user for it
        // this.checkUserExists(email, url);
      //  console.log('LS EMAIL', userEmail);
        if(userEmail){
          sessionStorage.setItem('LoggedInUserEmail', JSON.stringify(userEmail));
        }

        if (!userEmail) {
          userEmail = window.prompt('Please provide your email for confirmation');
        }
          // const result = await this.afAuth.signInWithEmailLink(userEmail, url);
          const docRef = await this.readService.returnPromiseWhere(
          `${GlobalConstants.rootEmployeeCollection}`,
          'personEmail',
          `${userEmail}`
          );

          if(!docRef?.length){
          const randomUid = this.afs.createId();
          const branchName = JSON.parse(sessionStorage.getItem('CSCBranch'));
          const employeeNumber = JSON.parse(sessionStorage.getItem('PersonEmployeeNumber'));
            const formData = {
              uid: randomUid,
              docId: userEmail,
              personEmail: userEmail,
              createId: userEmail,
              readId: userEmail,
              updateId: userEmail,
              deleteId: userEmail,
              personEmployeeNumber: employeeNumber,
              cscBranch: branchName,
              signUpDate: firebase.firestore.FieldValue.serverTimestamp(),
              emailVerified: true,
              signUpBranchDocId: `${GlobalConstants.globalBranchDocId}`,
              signUpBranchName: `${GlobalConstants.branchNameTitleCase}`
            }
            this.createService.createItRecordFn(
              `${GlobalConstants.rootEmployeeCollection}`,
              userEmail,
              formData
            )
            const subscribeId = this.afs.createId();
            const consentToEmailContact = {
              emailNotificationAuthorization: true,
              docId: userEmail,
              personEmail: userEmail,
              createId: userEmail,
              readId: userEmail,
              updateId: userEmail,
              deleteId: userEmail,
              subscribeId,
              branchDocId: `${GlobalConstants.globalBranchDocId}`,
              createdAt: new Date()
            }
          //  const emailConsent = this.afs.createId();
            this.createService.createItRecordFn(
              `${GlobalConstants.rootEmployeeCollection}/${userEmail}/emailNotificationAuthorization`,
              `${GlobalConstants.globalBranchDocId}`,
              consentToEmailContact
            );

            const message = 'Signing in...';
          const snackClass = ['snackSuccess'];
          this.router.navigateByUrl('/account');
          this.snackbarService.openSnackBar(message, snackClass);
          } else {
            this.personLegalNameFirst = JSON.parse(sessionStorage.getItem('PersonLegalNameFirst'));
            this.isAlreadyRegistered = true;
            // setTimeout(() => {
            //   this.router.navigateByUrl('/signin');
            // }, 2000);
          }
      }
    } catch (e) {
      // console.log('ERROR', e);
      // console.log('ERROR MSG', e.message);
      const snackClass = ['snackError'];
      const message = `LOGIN FAILED`;
      this.snackbarService.openSnackBar(message, snackClass);
    }
  }


  async checkUserRecordFn() {
    const branchName = JSON.parse(sessionStorage.getItem('CSCBranch'));
    const employeeNumber = JSON.parse(sessionStorage.getItem('PersonEmployeeNumber'));

    if (GlobalConstants.branchNameLowerCase === branchName) {
      const e = await this.readService.returnPromiseWhere(
        `${GlobalConstants.rootEmployeeCollection}`,
        'personEmployeeNumber',
        employeeNumber
      );

    //  console.log('Epm Num Check', e);

      if (e?.length) {
        this.isAlreadyRegistered = true;
        // setTimeout(() => {
        //   this.router.navigateByUrl('/signin');
        // }, 2000);
      } else {
       // console.log(`Branch: ${branchName} matches`);
        sessionStorage.setItem('CSCBranch', JSON.stringify(branchName));
        const branchSpaceReplace = branchName.replace(/ /g, '-');
        sessionStorage.setItem(
          'branchDocId',
          JSON.stringify(branchSpaceReplace)
        );
        this.isVerifiedEmployee = true;
      }
    } else {
     // console.log(
    //    `Your branch is ${branchName}, you are not permitted to access ${GlobalConstants.branchNameTitleCase}`
    //  );
    }
  }
}
