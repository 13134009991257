import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './public/homepage/homepage.component';
import { HeaderVideoComponent } from './public/header-video/header-video.component';
import { CscSofiComponent } from './public/csc-sofi/csc-sofi.component';
import { LegendsSofiComponent } from './public/legends-sofi/legends-sofi.component';
import { PasswordlessComponent } from './auth/passwordless/passwordless.component';
import { BranchBioComponent } from './public/branch-bio/branch-bio.component';
import { HeaderComponent } from './public/header/header.component';
import { HomepageVenuesComponent } from './public/homepage-venues/homepage-venues.component';
import { HomepageClientsComponent } from './public/homepage-clients/homepage-clients.component';
import { WishApiTestComponent } from './public/wish-api-test/wish-api-test.component';
import { sectionMetadata } from './public/sections-metadata';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home',
   data: sectionMetadata.homePage,
   component: HomepageComponent
  },
  {path: 'api-test', component: WishApiTestComponent},
  {path: 'passwordless', component: PasswordlessComponent},
  {path: 'email-unsubscribe', loadChildren: () => import('./public/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)},
  {path: 'view-email', loadChildren: () => import('./public/view-email/view-email.module').then(m => m.ViewEmailModule)},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [
  ]
})
export class AppRoutingModule {
  static components = [
    HomepageComponent,
    HeaderVideoComponent,
    CscSofiComponent,
    LegendsSofiComponent,
    PasswordlessComponent,
    BranchBioComponent,
    HeaderComponent,
    HomepageVenuesComponent,
    HomepageClientsComponent,
    WishApiTestComponent
  ];
}
