import { Injectable } from '@angular/core';
import { ReadService } from 'src/app/services/read.service';
import { UnitedStatesListService } from 'src/app/services/united-states-list.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private readService: ReadService,
    private usStates: UnitedStatesListService
  ) { }

  verifyEmployeeFn(){
    return [
      {
        cssWrapperClass: 'personLegalNameLast',
        controlLabel: 'Last Name',
        controlName: 'personLegalNameLast',
        controlType: 'text',
        valueType: 'text',
        placeholder: 'Last Name',
        validators: {
        required: true,
        minlength: 2,
        maxlength: 50
        }
        },
        {
          cssWrapperClass: 'personEmployeeNumber',
          controlLabel: 'Employee Number',
          controlName: 'personEmployeeNumber',
          controlType: 'numberOnly',
          valueType: 'text',
          placeholder: 'Employee Number',
          validators: {
          required: true,
          minlength: 5,
          maxlength: 10
          }
          }
// {
//           cssWrapperClass: 'testAndProdUrls',
//           controlLabel: 'Test & Prod URLs',
//           controlName: 'testAndProdUrls',
//           controlType: 'radio',
//           valueType: 'text',
//           placeholder: 'Test & Prod URLs',
//           options: [
//             {
//               optionName: 'TEST',
//               value: 'http://esssrv12.schedulingsite.com/api/User/GetEmployeeContact'
//             },
//             {
//               optionName: 'PROD',
//               value:  'https://essapiv2.schedulingsite.com/api/User/GetEmployeeContact'
//             },
//           ],
//           validators: {
//             required: true
//           }
// }


    ]
  }
  professionalCertificateFn(){
    return [
      {
        cssWrapperClass: 'certificateName',
        controlLabel: 'Certificate Title',
        controlName: 'certificateName',
        controlType: 'text',
        valueType: 'text',
        placeholder: 'Certificate Title',
        validators: {
        required: true,
        minlength: 2,
        maxlength: 150
        }
        }
    ];
  }
  profilePersonalInfoFn(){
    return [
      {
        cssWrapperClass: 'personLegalNameFirst',
        controlLabel: 'First Name',
        controlName: 'personLegalNameFirst',
        controlType: 'text',
        valueType: 'text',
        placeholder: 'First Name',
        validators: {
        required: true,
        minlength: 2,
        maxlength: 50
        }
        },
        {
          cssWrapperClass: 'personLegalNameLast',
          controlLabel: 'Last Name',
          controlName: 'personLegalNameLast',
          controlType: 'text',
          valueType: 'text',
          placeholder: 'Last Name',
          validators: {
          required: true,
          minlength: 2,
          maxlength: 50
          }
          }
    ];
  }

  profilePersonaCellPhoneFn(){
    return [
            {
              cssWrapperClass: 'personCellPhone',
              controlLabel: 'Add Cell Phone # (Optional)',
              controlName: 'personCellPhone',
              controlType: 'numberOnly',
              valueType: 'text',
              placeholder: 'Add Cell Phone # (Optional)',
              validators: {
              minlength: 10,
              maxlength: 10
              }
              }
    ];
  }

  profilePersonaEmployeeNumberFn(){
    return [
            {
              cssWrapperClass: 'personEmployeeNumber',
              controlLabel: 'Employee Number',
              controlName: 'personEmployeeNumber',
              controlType: 'numberOnly',
              valueType: 'text',
              placeholder: 'Employee Number',
              validators: {
              required: true,
              minlength: 5,
              maxlength: 10
              }
              }
    ];
  }

  profileBioFn(){
    return [
            {
              cssWrapperClass: 'personBio',
              controlLabel: 'Your Bio',
              controlName: 'personBio',
              controlType: 'textarea',
              valueType: 'text',
              placeholder: 'Your Bio',
              validators: {
              minlength: 50,
              maxlength: 1000
              }
              }
    ];
  }

  previousRelatedExperienceFn(){
    return [
            {
              cssWrapperClass: 'previousRelatedExperience',
              controlLabel: 'Related Experience',
              controlName: 'previousRelatedExperience',
              controlType: 'textarea',
              valueType: 'text',
              placeholder: 'Related Experience',
              validators: {
              minlength: 50,
              maxlength: 1000
              }
              }
    ];
  }

  async guardCardInfoFn(){
    return [
          {
            cssWrapperClass: 'personGuardCardNumber',
            controlLabel: 'Guard Card Number',
            controlName: 'personGuardCardNumber',
            controlType: 'text',
            valueType: 'text',
            placeholder: 'Guard Card Number',
            validators: {
              required: true,
            minlength: 5,
            maxlength: 25
            }
            },

          {
            cssWrapperClass: 'personGuardCardExpirationDate',
            controlLabel: 'Expiration Date',
            controlName: 'personGuardCardExpirationDate',
            controlType: 'date',
            valueType: 'text',
            placeholder: 'Guard Card Expiration Date',
            validators: {
              required: true,
            minlength: 8,
            maxlength: 10
            }
            },
            {
              cssWrapperClass: 'guardCardStateIssuer',
              controlLabel: 'Issuing State',
              controlName: 'guardCardStateIssuer',
              controlType: 'select',
              valueType: 'text',
              placeholder: 'Issuing State',
              options: await this.readService.returnUSStaestPromiseFn(),
              validators: {
                required: true,
              minlength: 5,
              maxlength: 85
              }
              },
    ];
  }
  profileGuardCardInfoFn(){
    return [
          {
            cssWrapperClass: 'personGuardCardNumber',
            controlLabel: 'Guard Card Number',
            controlName: 'personGuardCardNumber',
            controlType: 'text',
            valueType: 'text',
            placeholder: 'Guard Card Number',
            validators: {
              required: true,
            minlength: 5,
            maxlength: 25
            }
            },
          {
            cssWrapperClass: 'personGuardCardExpirationDate',
            controlLabel: 'Expiration Date',
            controlName: 'personGuardCardExpirationDate',
            controlType: 'date',
            valueType: 'text',
            placeholder: 'Guard Card Expiration Date',
            validators: {
              required: true,
            minlength: 8,
            maxlength: 10
            }
            }
    ];
  }
  checkDateFn(){
   const dateFormat = /^(?=\d{2}([-.,\/])\d{2}\1\d{4}$)(?:0[1-9]|1\d|[2][0-8]|29(?!.02.(?!(?!(?:[02468][1-35-79]|[13579][0-13-57-9])00)\d{2}(?:[02468][048]|[13579][26])))|30(?!.02)|31(?=.(?:0[13578]|10|12))).(?:0[1-9]|1[012]).\d{4}$/;
    return dateFormat
  }
  profileContactPhoneAuthorizationFn(){
    return [
            {
                cssWrapperClass: 'cellPhoneNotificationAuthorization',
                controlLabel: 'Cell Phone Notification Authorization',
                controlName: 'cellPhoneNotificationAuthorization',
                controlType: 'radio',
                legalsCSSClass: 'legalNotice',
                legalNotice: 'By selecting Yes you agree to be contacted via phone about this event. If no phone number is listed on your profile we will contact you at the email address provided when you signed up.',
                valueType: 'text',
                placeholder: 'Cell Phone Notification Authorization',
                options: [
                  {
                    optionName: 'Yes',
                    value: true
                  },
                  {
                    optionName: 'No',
                    value:  false
                  },
                ],
                validators: {
                  required: true
                }
              }
    ];
  }
  profileContactEmailAuthorizationFn(){
    return [
             {
                cssWrapperClass: 'emailNotificationAuthorization',
                controlLabel: 'Email Notification Authorization',
                controlName: 'emailNotificationAuthorization',
                controlType: 'radio',
                valueType: 'text',
                placeholder: 'Email Notification Authorization',
                options: [
                  {
                    optionName: 'Yes',
                    value: true
                  },
                  {
                    optionName: 'No',
                    value:  false
                  },
                ],
                validators: {
                  required: true
                }
              }

    ];
  }
}
